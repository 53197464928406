import React, { useState, useEffect, useRef } from "react";
import Layoutas from "../../components/Layout";
import Mapbox from "../../mapbox";
import LegendCard from "../../mapbox/LegendCard";
import Calculate from "../../util/calculate";

import Sidetab from "../../components/Sidetab";
import Overview from "./map_cct_contents/Overview";
import Semesterview from "./map_cct_contents/Semesterview";
import MapAreaView from "./map_cct_contents/MapAreaView";
import SungkudView from "./map_cct_contents/SungkudView";
import Jon100View from "./map_cct_contents/Jon100View";
import { Modal, Divider, Select, Button } from "antd";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import axios from 'axios';

import TumbonAddr from "../../util/TumbonAddr";

import { FileExcelOutlined } from "@ant-design/icons";
import marker_obec from "/images/marker/dot-obec.svg";
import marker_dla from "/images/marker/dot-dla.svg";
import marker_bpp from "/images/marker/dot-bpp.svg";
import marker_onab from "/images/marker/dot-onab.svg";
import marker_opec from "/images/marker/dot-opec.svg";
import marker_jon100 from "/images/marker/dot-jon100.svg";
import marker_bkk from "/images/marker/dot-bkk.svg";
import api from "../../util/api";
import getProvinceName from "../../util/getProvinceName";

import "../index.css";

const { Option } = Select;

const MAP_TEXT = {
  "อยู่ในจังหวัดตามพระราชกฤษฎีกาจัดตั้งสถาบันวิจัยพัฒนาพื้นที่สูง (องค์การมหาชน) พ.ศ. 2548 เป็นพื้นที่ภูเขา  หรือพื้นที่ที่ที่มีความสูงกว่าระดับน้ำทะเล 500 เมตร ขึ้นไป":
    "พื้นที่สูง",
  พื้นราบ: "พื้นที่ราบ",
  บนเกาะ: "พื้นที่เกาะ",
  "โรงเรียนที่ตั้งอยู่บนสันเขา เชิงเขา หรือพื้นที่ระหว่างหุบเขาที่มีความยากลำบากในการเดินทาง": "พื้นที่เขา",
  โรงเรียนที่เป็นพื้นที่สูงและชายแดน: "พื้นที่ติดชายแดน",
};

const FullMap = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(true);
  const [tabvalue, setTabValue] = React.useState(0);

  const [data_map, setDataMap] = useState([]);
  const [border_color] = useState(["#1b5e20", "#388e3c", "#66bb6a", "#a5d6a7", "#94a3b8"]);
  const is_Click = useRef(false);
  const [modalSchool, setModalSchool] = useState({
    visible: false,
    resource: null,
  });
  const [modalExport, setModalExport] = useState({
    visible: false,
    data: null,
  });
  const [filter, setFilter] = React.useState({
    minView: "country",
    view: "country",
    year: "2567",
    semester: "1",
    sungkud: "ทั้งหมด",
    educlass: "ทั้งหมด",
    jontype: "exjon",
    province_name: "ทั้งหมด",
    city_name: null,
    tumbon_name: null,
  });
  const [legend_data, setLegendData] = useState({
    title: "",
    subtitle: "",
    data: [],
    footer: "",
  });
  const [dataTab, setDataTab] = useState({
    overview: {
      countAllTunStudent: 0,
      countAllTunSchool: 0,
      dataTableSungkud: {
        "สพฐ.": {
          key: "สพฐ.",
          school_size_s: 0,
          school_size_m: 0,
          school_size_l: 0,
          school_size_xl: 0,
          school_size_sum: 0,
          sum_exjon: 0,
          school_jon_sum: 0,
        },
        "อปท.": {
          key: "อปท.",
          school_size_s: 0,
          school_size_m: 0,
          school_size_l: 0,
          school_size_xl: 0,
          school_size_sum: 0,
          sum_exjon: 0,
          school_jon_sum: 0,
        },
        "ตชด.": {
          key: "ตชด.",
          school_size_s: 0,
          school_size_m: 0,
          school_size_l: 0,
          school_size_xl: 0,
          school_size_sum: 0,
          sum_exjon: 0,
          school_jon_sum: 0,
        },
        "สช.": {
          key: "สช.",
          school_size_s: 0,
          school_size_m: 0,
          school_size_l: 0,
          school_size_xl: 0,
          school_size_sum: 0,
          sum_exjon: 0,
          school_jon_sum: 0,
        },
        "พศ.": {
          key: "พศ.",
          school_size_s: 0,
          school_size_m: 0,
          school_size_l: 0,
          school_size_xl: 0,
          school_size_sum: 0,
          sum_exjon: 0,
          school_jon_sum: 0,
        },
        "กทม.": {
          key: "กทม.",
          school_size_s: 0,
          school_size_m: 0,
          school_size_l: 0,
          school_size_xl: 0,
          school_size_sum: 0,
          sum_exjon: 0,
          school_jon_sum: 0,
        },
      },
      student_max_exjon: 0,
      student_exjon_25671: 0,
      student_exjon_25662: 0,
      student_exjon_25661: 0,
      student_exjon_25652: 0,
      student_exjon_25651: 0,
      student_exjon_25642: 0,
      student_exjon_25641: 0,
      student_exjon_25632: 0,
      student_exjon_25631: 0,
      student_exjon_25622: 0,
      student_exjon_25621: 0,
      student_exjon_25612: 0,
    },
    semesterview: {
      student_total: 0,
      student_exjon: 0,
      school_jon: 0,
      school_total: 0,
      class_k: 0,
      class_p: 0,
      class_m: 0,
      class_h: 0,
    },
    areaview: {
      countAllTunStudent: 0,
      countAllTunSchool: 0,
      school: 0,
      student_exjon: 0,
      dataTable: [],
      key_group: "province_name",
    },
    sungkudview: {
      countAllTunStudent: 0,
      countAllTunSchool: 0,
      school_total: 0,
      students: 0,
      student_jon: 0,
      class_k: 0,
      class_p: 0,
      class_m: 0,
      class_h: 0,
      dataTable: [],
      school_jon100persent: 0,
      student_jon100persent: 0,
    },
    jon100view: {
      school_jon100persent: 0,
      student_jon100persent: 0,
      student_exjon100persent: 0,
      dataTable: [],
      dataTableJon100: [],
    },
  });

  const handleChangeTab = (event, newValue) => {
    if (newValue === 4) {
      setFilter({
        ...filter,
        ...{
          view: filter.minView,
          year: "2567",
          semester: "1",
          // year: "2564",
          // semester: "2",
          sungkud: "ทั้งหมด",
          educlass: "ทั้งหมด",
          jontype: "jon100",
          province_name: filter.minView !== "province" ? "ทั้งหมด" : filter.province_name,
          city_name: null,
          tumbon_name: null,
        },
      });
    } else {
      setFilter({
        ...filter,
        ...{
          view: filter.minView,
          year: "2567",
          semester: "1",
          // year: "2564",
          // semester: "2",
          sungkud: "ทั้งหมด",
          educlass: "ทั้งหมด",
          jontype: "exjon",
          province_name: filter.minView !== "province" ? "ทั้งหมด" : filter.province_name,
          city_name: null,
          tumbon_name: null,
        },
      });
    }
    setTabValue(newValue);
  };

  useEffect(async () => {
    let is_login = api.isLogin().status;
    let res_profile = await (is_login ? api.getProfileDb() : null);
    console.log("did mount");
    let [fullmap_data, res25612, res25621, res25622, res25631, res25632, res25641, res25642, res25651, res25652, res25661, res25662, res25671] =
      await Promise.all([
        api.getInitData("fullmap-cct"),
        api.getInitData("call-fullmap-cct-2561-2"),
        api.getInitData("call-fullmap-cct-2562-1"),
        api.getInitData("call-fullmap-cct-2562-2"),
        api.getInitData("call-fullmap-cct-2563-1"),
        api.getInitData("call-fullmap-cct-2563-2"),
        api.getInitData("call-fullmap-cct-2564-1"),
        api.getInitData("call-fullmap-cct-2564-2"),
        api.getInitData("call-fullmap-cct-2565-1"),
        api.getInitData("call-fullmap-cct-2565-2"),
        api.getInitData("call-fullmap-cct-2566-1"),
        api.getInitData("call-fullmap-cct-2566-2"),
        api.getInitData("call-fullmap-cct-2567-1"),
      ]);
    let obj = {};
    if (res_profile) {
      if (res_profile?.profile?.roles?.includes("partner_province") && api.getActiveRole() === "partner_province") {
        let pid = res_profile?.profile?.profile?.provinceId;
        obj = {
          ...fullmap_data.province[getProvinceName(pid)],
          data25612: res25612.data.filter((item) => item.province_name === getProvinceName(pid)),
          data25621: res25621.data.filter((item) => item.province_name === getProvinceName(pid)),
          data25622: res25622.data.filter((item) => item.province_name === getProvinceName(pid)),
          data25631: res25631.data.filter((item) => item.province_name === getProvinceName(pid)),
          data25632: res25632.data.filter((item) => item.province_name === getProvinceName(pid)),
          data25641: res25641.data.filter((item) => item.province_name === getProvinceName(pid)),
          data25642: res25642.data.filter((item) => item.province_name === getProvinceName(pid)),
          data25651: res25651.data.filter((item) => item.province_name === getProvinceName(pid)),
          data25652: res25652.data.filter((item) => item.province_name === getProvinceName(pid)),
          data25661: res25661.data.filter((item) => item.province_name === getProvinceName(pid)),
          data25662: res25662.data.filter((item) => item.province_name === getProvinceName(pid)),
          data25671: res25671.data.filter((item) => item.province_name === getProvinceName(pid)),
        };
        setFilter({ ...filter, minView: "province", view: "province", province_name: getProvinceName(pid) });
      } else {
        obj = {
          ...fullmap_data,
          data25612: res25612.data,
          data25621: res25621.data,
          data25622: res25622.data,
          data25631: res25631.data,
          data25632: res25632.data,
          data25641: res25641.data,
          data25642: res25642.data,
          data25651: res25651.data,
          data25652: res25652.data,
          data25661: res25661.data,
          data25662: res25662.data,
          data25671: res25671.data,
        };
      }
    }
    setData(obj);
    setLoading(false);
  }, []);

  // useEffect(async () => {
  //   let [
  //     fullmap_data,
  //     res25612,
  //     res25621,
  //     res25622,
  //     res25631,
  //     res25632,
  //     res25641,
  //     res25642,
  //     res25651,
  //     res25652,
  //     // cct
  //   ] = await Promise.all([
  //     api.getInitData("fullmap-cct"),
  //     api.getInitData("call-fullmap-cct-2561-2"),
  //     api.getInitData("call-fullmap-cct-2562-1"),
  //     api.getInitData("call-fullmap-cct-2562-2"),
  //     api.getInitData("call-fullmap-cct-2563-1"),
  //     api.getInitData("call-fullmap-cct-2563-2"),
  //     api.getInitData("call-fullmap-cct-2564-1"),
  //     api.getInitData("call-fullmap-cct-2564-2"),
  //     api.getInitData("call-fullmap-cct-2565-1"),
  //     api.getInitData("call-fullmap-cct-2565-2"),
  //   ]);
  //   setData({
  //     ...fullmap_data,
  //     data25612: res25612.data,
  //     data25621: res25621.data,
  //     data25622: res25622.data,
  //     data25631: res25631.data,
  //     data25632: res25632.data,
  //     data25641: res25641.data,
  //     data25642: res25642.data,
  //     data25651: res25651.data,
  //     data25652: res25652.data,
  //   });
  //   setLoading(false);
  // }, []);

  // Tab 1 Calculate
  useEffect(() => {
    if (data) {
      let years = ["25612", "25621", "25622", "25631", "25632", "25641", "25642", "25651", "25652", "25661", "25662", "25671"];
      let dataTableSungkud = {
        "สพฐ.": {
          key: "สพฐ.",
          school_size_s: 0,
          school_size_m: 0,
          school_size_l: 0,
          school_size_xl: 0,
          school_size_sum: 0,
          sum_exjon: 0,
          school_jon_sum: 0,
        },
        "อปท.": {
          key: "อปท.",
          school_size_s: 0,
          school_size_m: 0,
          school_size_l: 0,
          school_size_xl: 0,
          school_size_sum: 0,
          sum_exjon: 0,
          school_jon_sum: 0,
        },
        "ตชด.": {
          key: "ตชด.",
          school_size_s: 0,
          school_size_m: 0,
          school_size_l: 0,
          school_size_xl: 0,
          school_size_sum: 0,
          sum_exjon: 0,
          school_jon_sum: 0,
        },
        "สช.": {
          key: "สช.",
          school_size_s: 0,
          school_size_m: 0,
          school_size_l: 0,
          school_size_xl: 0,
          school_size_sum: 0,
          sum_exjon: 0,
          school_jon_sum: 0,
        },
        "พศ.": {
          key: "พศ.",
          school_size_s: 0,
          school_size_m: 0,
          school_size_l: 0,
          school_size_xl: 0,
          school_size_sum: 0,
          sum_exjon: 0,
          school_jon_sum: 0,
        },
        "กทม.": {
          key: "กทม.",
          school_size_s: 0,
          school_size_m: 0,
          school_size_l: 0,
          school_size_xl: 0,
          school_size_sum: 0,
          sum_exjon: 0,
          school_jon_sum: 0,
        },
      };
      let key_group = "province_name";
      let objEx = {
        student_exjon_25612: 0,
        student_exjon_25621: 0,
        student_exjon_25622: 0,
        student_exjon_25631: 0,
        student_exjon_25632: 0,
        student_exjon_25641: 0,
        student_exjon_25642: 0,
        student_exjon_25651: 0,
        student_exjon_25652: 0,
        student_exjon_25661: 0,
        student_exjon_25662: 0,
        student_exjon_25671: 0,
      };
      let name = "";

      years.forEach((_year) => {
        if (data[`data${_year}`]) {
          let _data = data[`data${_year}`];
          if (filter.sungkud !== "ทั้งหมด") _data = _data.filter((item) => item.sungkud === filter.sungkud);
          if (filter.view === "country") {
            key_group = "province_name";
            _data = _data.map((x) => ({ ...x, type: "province_name" }));
          }
          if (filter.view === "province") {
            key_group = "city_name";
            name = "จังหวัด" + filter.province_name;
            _data = _data.filter((x) => x.province_name === filter.province_name).map((x) => ({ ...x, type: "city_name" }));
          }
          if (filter.view === "city") {
            key_group = "tumbon_name";
            name = "อำเภอ" + filter.city_name;
            _data = _data
              .filter((x) => x.province_name === filter.province_name && x.city_name === filter.city_name)
              .map((x) => ({ ...x, type: "tumbon_name" }));
          }
          _data.forEach((element) => {
            objEx[`student_exjon_${_year}`] += element.total_exjon;
          });
        }
      });

      if (data[`data${filter.year}${filter.semester}`]) {
        let _data = data[`data${filter.year}${filter.semester}`];
        if (filter.sungkud !== "ทั้งหมด") _data = _data.filter((item) => item.sungkud === filter.sungkud);
        if (filter.view === "country") {
          key_group = "province_name";
          _data = _data.map((x) => ({ ...x, type: "province_name" }));
        }
        if (filter.view === "province") {
          key_group = "city_name";
          name = "จังหวัด" + filter.province_name;
          _data = _data.filter((x) => x.province_name === filter.province_name).map((x) => ({ ...x, type: "city_name" }));
        }
        if (filter.view === "city") {
          key_group = "tumbon_name";
          name = "อำเภอ" + filter.city_name;
          _data = _data
            .filter((x) => x.province_name === filter.province_name && x.city_name === filter.city_name)
            .map((x) => ({ ...x, type: "tumbon_name" }));
        }
        let list_s = [];
        _data.forEach((element) => {
          list_s.push(element.hostid);
          if (element.sungkud) {
            if (element.school_size === "ขนาดเล็ก") dataTableSungkud[element.sungkud].school_size_s += 1;
            if (element.school_size === "ขนาดกลาง") dataTableSungkud[element.sungkud].school_size_m += 1;
            if (element.school_size === "ขนาดใหญ่") dataTableSungkud[element.sungkud].school_size_l += 1;
            if (element.school_size === "ขนาดใหญ่พิเศษ") dataTableSungkud[element.sungkud].school_size_xl += 1;
            dataTableSungkud[element.sungkud].school_size_sum += 1;
            dataTableSungkud[element.sungkud].sum_exjon += element.total_exjon;
            if (element.total_exjon > 0) {
              dataTableSungkud[element.sungkud].school_jon_sum += 1;
            }
          } else {
            console.log("element", element);
          }
        });

        setDataTab({
          ...dataTab,
          overview: {
            countAllTunStudent: data?.countAllTunStudent ? data.countAllTunStudent : 0,
            countAllTunSchool: data?.countAllTunSchool ? data.countAllTunSchool : 0,
            dataTableSungkud: dataTableSungkud,
            student_exjon_25612: objEx.student_exjon_25612,
            student_exjon_25621: objEx.student_exjon_25621,
            student_exjon_25622: objEx.student_exjon_25622,
            student_exjon_25631: objEx.student_exjon_25631,
            student_exjon_25632: objEx.student_exjon_25632,
            student_exjon_25641: objEx.student_exjon_25641,
            student_exjon_25642: objEx.student_exjon_25642,
            student_exjon_25651: objEx.student_exjon_25651,
            student_exjon_25652: objEx.student_exjon_25652,
            student_exjon_25661: objEx.student_exjon_25661,
            student_exjon_25662: objEx.student_exjon_25662,
            student_exjon_25671: objEx.student_exjon_25671,
            student_max_exjon: Math.max(
              objEx.student_exjon_25612,
              objEx.student_exjon_25621,
              objEx.student_exjon_25622,
              objEx.student_exjon_25631,
              objEx.student_exjon_25632,
              objEx.student_exjon_25641,
              objEx.student_exjon_25642,
              objEx.student_exjon_25651,
              objEx.student_exjon_25652,
              objEx.student_exjon_25661,
              objEx.student_exjon_25662,
              objEx.student_exjon_25671
            ),
          },
        });
      }
    }
  }, [data, filter]);

  // Tab 2,3,4 Calculate
  useEffect(() => {
    if (data) {
      if (tabvalue === 1) {
        let student_total = 0;
        let student_exjon = 0;
        let school_jon = 0;
        let school_total = 0;
        let class_k = 0;
        let class_p = 0;
        let class_m = 0;
        let class_h = 0;
        let key_group = "province_name";
        let name = "";

        if (data[`data${filter.year}${filter.semester}`]) {
          let _data = data[`data${filter.year}${filter.semester}`];
          if (filter.sungkud !== "ทั้งหมด") _data = _data.filter((item) => item.sungkud === filter.sungkud);
          if (filter.view === "country") {
            key_group = "province_name";
            _data = _data.map((x) => ({ ...x, type: "province_name" }));
          }
          if (filter.view === "province") {
            key_group = "city_name";
            name = "จังหวัด" + filter.province_name;
            _data = _data.filter((x) => x.province_name === filter.province_name).map((x) => ({ ...x, type: "city_name" }));
          }
          if (filter.view === "city") {
            key_group = "tumbon_name";
            name = "อำเภอ" + filter.city_name;
            _data = _data
              .filter((x) => x.province_name === filter.province_name && x.city_name === filter.city_name)
              .map((x) => ({ ...x, type: "tumbon_name" }));
          }

          _data.forEach((element) => {
            student_exjon += element.total_exjon;
            school_total += 1;
            if (element.total_exjon > 0) {
              school_jon += 1;
            }
            student_total += element.total_student;
            if (element.k_exjon > 0) class_k += element.k_exjon;
            if (element.p_exjon > 0) class_p += element.p_exjon;
            if (element.m_exjon > 0) class_m += element.m_exjon;
            if (element.h_exjon > 0) class_h += element.h_exjon;
          });
          setDataTab({
            ...dataTab,
            semesterview: {
              school_total: school_total,
              school_jon: school_jon,
              student_exjon: student_exjon,
              student_total: student_total,
              class_k,
              class_p,
              class_m,
              class_h,
            },
          });
        }
      } else if (tabvalue === 2) {
        let student_exjon = 0;
        let school = 0;
        let key_group = "province_name";
        let result = {};
        let name = "";

        if (data[`data${filter.year}${filter.semester}`]) {
          let _data = data[`data${filter.year}${filter.semester}`];

          if (filter.sungkud !== "ทั้งหมด") _data = _data.filter((item) => item.sungkud === filter.sungkud);

          if (filter.view === "country") {
            key_group = "province_name";
            _data = _data.map((x) => ({ ...x, type: "province_name" }));
          }

          if (filter.view === "province") {
            key_group = "city_name";
            name = "จังหวัด" + filter.province_name;
            _data = _data.filter((x) => x.province_name === filter.province_name).map((x) => ({ ...x, type: "city_name" }));
          }

          if (filter.view === "city") {
            key_group = "tumbon_name";
            name = "อำเภอ" + filter.city_name;
            _data = _data
              .filter((x) => x.province_name === filter.province_name && x.city_name === filter.city_name)
              .map((x) => ({ ...x, type: "tumbon_name" }));
          }

          _data.forEach((element) => {
            school += 1;
            student_exjon += element[`total_exjon`];
            if (!result[element[key_group]]) {
              result[element[key_group]] = {
                key: element[key_group],
                school: 1,
                student: element[`total_student`],
                student_target: element[`total_target`],
                student_jon: element[`total_jon`],
                student_exjon: element[`total_exjon`],
                sum_jon_exjon: element[`total_jon`] + element[`total_exjon`],
              };
            } else {
              result[element[key_group]].school += 1;
              result[element[key_group]].student += element[`total_student`];
              result[element[key_group]].student_target += element[`total_target`];
              result[element[key_group]].student_jon += element[`total_jon`];
              result[element[key_group]].student_exjon += element[`total_exjon`];
              result[element[key_group]].sum_jon_exjon += element[`total_jon`] + element[`total_exjon`];
            }
          });

          setDataTab({
            ...dataTab,
            areaview: {
              countAllTunStudent: data?.countAllTunStudent ? data.countAllTunStudent : 0,
              countAllTunSchool: data?.countAllTunSchool ? data.countAllTunSchool : 0,
              school: school,
              student_exjon: student_exjon,
              dataTable: [...Object.values(result)],
              key_group,
              sum_student: 0,
            },
          });
        }
      } else if (tabvalue === 3) {
        let student_exjon = 0;
        let school = 0;
        let school_total = 0;
        let key_group = "province_name";
        let result = {};
        let name = "";
        let class_k = 0;
        let class_p = 0;
        let class_m = 0;
        let class_h = 0;
        let school_jon = 0;
        let student_jon = 0;
        let student_jon100persent = 0;
        let school_jon100persent = 0;
        let exjon = 0;
        let dataTable = {
          ขนาดเล็ก: { key: "เล็ก", school: 0, student: 0, exjon: 0 },
          ขนาดกลาง: { key: "กลาง", school: 0, student: 0, exjon: 0 },
          ขนาดใหญ่: { key: "ใหญ่", school: 0, student: 0, exjon: 0 },
          ขนาดใหญ่พิเศษ: { key: "ใหญ่พิเศษ", school: 0, student: 0, exjon: 0 },
        };

        if (data[`data${filter.year}${filter.semester}`]) {
          let _data = data[`data${filter.year}${filter.semester}`];
          let school_total = 0;
          // _data.length
          if (filter.sungkud !== "ทั้งหมด") _data = _data.filter((item) => item.sungkud === filter.sungkud);

          if (filter.view === "country") {
            key_group = "province_name";
            _data = _data.map((x) => ({ ...x, type: "province_name" }));
          }

          if (filter.view === "province") {
            key_group = "city_name";
            name = "จังหวัด" + filter.province_name;
            _data = _data.filter((x) => x.province_name === filter.province_name).map((x) => ({ ...x, type: "city_name" }));
          }

          if (filter.view === "city") {
            key_group = "tumbon_name";
            name = "อำเภอ" + filter.city_name;
            _data = _data
              .filter((x) => x.province_name === filter.province_name && x.city_name === filter.city_name)
              .map((x) => ({ ...x, type: "tumbon_name" }));
          }

          // list_school
          _data.forEach((element) => {
            school += 1;
            school_total += 1;
            student_exjon += element[`total_exjon`];

            if (!result[element[key_group]]) {
              result[element[key_group]] = {
                key: element[key_group],
                school: 0,
                student: 0,
                student_jon: 0,
                student_exjon: 0,
                sum_jon_exjon: 0,

                class_k: 0,
                class_p: 0,
                class_m: 0,
                class_h: 0,
                school_jon100: 0,
              };
            }
            result[element[key_group]].school += 1;
            result[element[key_group]].student += element[`total_student`];
            result[element[key_group]].student_jon += element[`total_jon`];
            result[element[key_group]].student_exjon += element[`total_exjon`];
            result[element[key_group]].sum_jon_exjon += element[`total_jon`] + element[`total_exjon`];

            exjon += element[`total_exjon`];

            if (element.k_exjon > 0) class_k += element.k_exjon;
            if (element.p_exjon > 0) class_p += element.p_exjon;
            if (element.m_exjon > 0) class_m += element.m_exjon;
            if (element.h_exjon > 0) class_h += element.h_exjon;
            // school_jon = (class_k + class_p + class_m + class_h)
            if (element.total_exjon > 0) {
              school_jon += 1;
            }
            student_jon += element.total_exjon;
            if (dataTable[element.school_size]) {
              dataTable[element.school_size].school += 1;
              dataTable[element.school_size].student += element.total_student;
              dataTable[element.school_size].exjon += element.total_exjon;
            }
            if (element.school_jon100 === 1) {
              school_jon100persent += 1;
              student_jon100persent += element.total_exjon;
              result[element[key_group]].school_jon100persent += 1;
              result[element[key_group]].student_jon100persent += element.total_exjon;
            }
          });
          setDataTab({
            ...dataTab,
            sungkudview: {
              countAllTunStudent: data?.countAllTunStudent ? data.countAllTunStudent : 0,
              countAllTunSchool: data?.countAllTunSchool ? data.countAllTunSchool : 0,
              school_total,
              school: school,
              student_exjon: student_exjon,
              dataTable: [...Object.values(dataTable)],
              key_group,
              class_k,
              class_p,
              class_m,
              class_h,
              school_jon,
              student_jon,
              school_jon100persent,
              student_jon100persent,
            },
          });
        }
      } else if (tabvalue === 4) {
        let student_exjon = 0;
        let school_total = 0;
        let school = 0;
        let key_group = "province_name";
        let result = {};
        let name = "";
        let class_k = 0;
        let class_p = 0;
        let class_m = 0;
        let class_h = 0;
        let school_jon = 0;
        let student_jon = 0;
        let student_jon100persent = 0;
        let student_exjon100persent = 0;
        let school_jon100persent = 0;
        let exjon = 0;
        let dataTable = {
          ตะวันออกเฉียงเหนือ: {
            key: "ตะวันออกเฉียงเหนือ",
            school: 0,
            student: 0,
            jon: 0,
            exjon: 0,
          },
          กลาง: { key: "กลาง", school: 0, student: 0, jon: 0, exjon: 0 },
          เหนือ: { key: "เหนือ", school: 0, student: 0, jon: 0, exjon: 0 },
          ใต้: { key: "ใต้", school: 0, student: 0, jon: 0, exjon: 0 },
        };

        let dataTableSungkud = {
          "สพฐ.": {
            key: "สพฐ.",
            school_size_s: 0,
            school_size_m: 0,
            school_size_l: 0,
            school_size_xl: 0,
            school_size_sum: 0,
          },
          "อปท.": {
            key: "อปท.",
            school_size_s: 0,
            school_size_m: 0,
            school_size_l: 0,
            school_size_xl: 0,
            school_size_sum: 0,
          },
          "ตชด.": {
            key: "ตชด.",
            school_size_s: 0,
            school_size_m: 0,
            school_size_l: 0,
            school_size_xl: 0,
            school_size_sum: 0,
          },
          "สช.": {
            key: "สช.",
            school_size_s: 0,
            school_size_m: 0,
            school_size_l: 0,
            school_size_xl: 0,
            school_size_sum: 0,
          },
          "พศ.": {
            key: "พศ.",
            school_size_s: 0,
            school_size_m: 0,
            school_size_l: 0,
            school_size_xl: 0,
            school_size_sum: 0,
          },
        };

        if (data[`data${filter.year}${filter.semester}`]) {
          let _data = data[`data${filter.year}${filter.semester}`];

          if (filter.sungkud !== "ทั้งหมด") _data = _data.filter((item) => item.sungkud === filter.sungkud);

          if (filter.view === "country") {
            key_group = "province_name";
            _data = _data.map((x) => ({ ...x, type: "province_name" }));
          }

          if (filter.view === "province") {
            key_group = "city_name";
            name = "จังหวัด" + filter.province_name;
            _data = _data.filter((x) => x.province_name === filter.province_name).map((x) => ({ ...x, type: "city_name" }));
          }

          if (filter.view === "city") {
            key_group = "tumbon_name";
            name = "อำเภอ" + filter.city_name;
            _data = _data
              .filter((x) => x.province_name === filter.province_name && x.city_name === filter.city_name)
              .map((x) => ({ ...x, type: "tumbon_name" }));
          }

          _data.forEach((element) => {
            if (element.school_jon100 === 1) {
              school_jon100persent += 1;
            }
          });

          _data.forEach((element) => {
            school += 1;
            student_exjon += element.total_exjon;

            if (!result[element[key_group]]) {
              result[element[key_group]] = {
                key: element[key_group],
                school: 0,
                student: 0,
                student_jon: 0,
                student_exjon: 0,
                sum_jon_exjon: 0,

                class_k: 0,
                class_p: 0,
                class_m: 0,
                class_h: 0,
                school_jon100: 0,
                student_jon100persent: 0,
                student_exjon100persent: 0,
                school_jon100persent: 0,
              };
            }
            result[element[key_group]].school += 1;
            result[element[key_group]].student += element[`total_student`];
            result[element[key_group]].student_jon += element[`total_jon`];
            result[element[key_group]].student_exjon += element[`total_exjon`];
            result[element[key_group]].sum_jon_exjon += element[`total_jon`] + element[`total_exjon`];

            exjon += element[`total_exjon`];

            school_total += 1;
            student_jon += element.total_exjon;
            if (element.total_exjon > 0) {
              school_jon += 1;
            }
            if (element.k_exjon > 0) class_k += element.k_exjon;
            if (element.p_exjon > 0) class_p += element.p_exjon;
            if (element.m_exjon > 0) class_m += element.m_exjon;
            if (element.h_exjon > 0) class_h += element.h_exjon;

            if (element.school_jon100 === 1) {
              // school_jon100persent += 1
              student_jon100persent += element.total_jon;
              student_exjon100persent += element.total_exjon;
              result[element[key_group]].school_jon100persent += 1;
              result[element[key_group]].student_jon100persent += element.total_jon;
              result[element[key_group]].student_exjon100persent += element.total_exjon;

              if (dataTable[element.region_thai]) {
                dataTable[element.region_thai].school += 1;
                dataTable[element.region_thai].student += element.total_student;
                dataTable[element.region_thai].jon += element.total_jon;
                dataTable[element.region_thai].exjon += element.total_exjon;
              }

              if (element.sungkud) {
                if (element.school_size === "ขนาดเล็ก") dataTableSungkud[element.sungkud].school_size_s += 1;
                if (element.school_size === "ขนาดกลาง") dataTableSungkud[element.sungkud].school_size_m += 1;
                if (element.school_size === "ขนาดใหญ่") dataTableSungkud[element.sungkud].school_size_l += 1;
                if (element.school_size === "ขนาดใหญ่พิเศษ") dataTableSungkud[element.sungkud].school_size_xl += 1;
                dataTableSungkud[element.sungkud].school_size_sum += 1;
              }
            }
          });
          setDataTab({
            ...dataTab,
            jon100view: {
              school: school,
              student_exjon: student_exjon,
              dataTableJon100: [...Object.values(result)].sort((a, b) => b.school_jon100persent - a.school_jon100persent),
              dataTable: [...Object.values(dataTable)],
              dataTableSungkud: [...Object.values(dataTableSungkud)],
              key_group,
              class_k,
              class_p,
              class_m,
              class_h,
              school_jon,
              student_jon,
              school_jon100persent,
              student_jon100persent,
              student_exjon100persent,
            },
          });
        }
      }
    }
  }, [data, filter]);

  // Map Calculate
  useEffect(() => {
    if (loading) return;
    if (data) {
      if (filter["jontype"] === "jon100") {
        let data_filter = data["data" + filter.year + filter.semester];
        if (filter.sungkud !== "ทั้งหมด") {
          data_filter = data_filter.filter((item) => item.sungkud === filter.sungkud);
        }
        if (filter.province_name !== "ทั้งหมด") {
          data_filter = data_filter.filter((item) => item.province_name === filter.province_name);
          if (filter.city_name) {
            data_filter = data_filter.filter((item) => item.city_name === filter.city_name);
          }
        }
        setDataMap([
          { id: "border", layer: null, data: [] },
          { id: "school_obec", layer: "marker", data: [], icon: marker_obec },
          { id: "school_dla", layer: "marker", data: [], icon: marker_dla },
          { id: "school_bpp", layer: "marker", data: [], icon: marker_bpp },
          { id: "school_opec", layer: "marker", data: [], icon: marker_opec },
          { id: "school_onab", layer: "marker", data: [], icon: marker_onab },
          { id: "school_nkk", layer: "marker", data: [], icon: marker_bkk },
          {
            id: "school_jon100",
            layer: "marker",
            data: data_filter.filter((item) => item.school_jon100 === 1).map((item) => ({ ...item, type: "school_jon100", fund: "cct" })),
            icon: marker_jon100,
          },
        ]);
      } else {
        let dataContext = data["data" + filter.year + filter.semester];
        let response = onProcessData(
          {
            sungkud: filter["sungkud"],
            educlass: filter["educlass"],
            jontype: filter["jontype"],
            province: filter["province_name"],
            city: filter["city_name"],
            tumbon: filter["tumbon_name"],
            layer: filter["view"],
          },
          convertMapContent(filter, dataContext)
        );
        if (filter["view"] === "city") {
          let data_filter = [];
          if (!filter.province_name || filter.province_name !== "ทั้งหมด") {
            if (filter.city_name !== null) {
              data_filter = [
                ...new Set(dataContext.filter((item) => item.province_name === filter.province_name && item.city_name === filter.city_name)),
              ];
            } else {
              data_filter = [...new Set(dataContext.filter((item) => item.province_name === filter.province_name))];
            }
          }
          setDataMap([
            { id: "border", layer: filter.view, data: response.data },
            {
              id: "school_obec",
              layer: "marker",
              data: data_filter.filter((item) => item.sungkud === "สพฐ.").map((item) => ({ ...item, type: "school_obec", fund: "cct" })),
              icon: marker_obec,
            },
            {
              id: "school_dla",
              layer: "marker",
              data: data_filter.filter((item) => item.sungkud === "อปท.").map((item) => ({ ...item, type: "school_dla", fund: "cct" })),
              icon: marker_dla,
            },
            {
              id: "school_bpp",
              layer: "marker",
              data: data_filter.filter((item) => item.sungkud === "ตชด.").map((item) => ({ ...item, type: "school_bpp", fund: "cct" })),
              icon: marker_bpp,
            },
            {
              id: "school_opec",
              layer: "marker",
              data: data_filter.filter((item) => item.sungkud === "สช.").map((item) => ({ ...item, type: "school_opec", fund: "cct" })),
              icon: marker_opec,
            },
            {
              id: "school_onab",
              layer: "marker",
              data: data_filter.filter((item) => item.sungkud === "พศ.").map((item) => ({ ...item, type: "school_onab", fund: "cct" })),
              icon: marker_onab,
            },
            {
              id: "school_bkk",
              layer: "marker",
              data: data_filter.filter((item) => item.sungkud === "กทม.").map((item) => ({ ...item, type: "school_bkk", fund: "cct" })),
              icon: marker_bkk,
            },
            {
              id: "school_jon100",
              layer: "marker",
              data: [],
              icon: marker_jon100,
            },
          ]);
        } else {
          setDataMap([
            { id: "border", layer: filter.view, data: response.data },
            { id: "school_obec", layer: "marker", data: [], icon: marker_obec },
            { id: "school_dla", layer: "marker", data: [], icon: marker_dla },
            { id: "school_bpp", layer: "marker", data: [], icon: marker_bpp },
            { id: "school_opec", layer: "marker", data: [], icon: marker_opec },
            { id: "school_onab", layer: "marker", data: [], icon: marker_onab },
            { id: "school_bkk", layer: "marker", data: [], icon: marker_bkk },
            {
              id: "school_jon100",
              layer: "marker",
              data: [],
              icon: marker_jon100,
            },
          ]);
        }

        let data_legend = [
          {
            name: `นร.กลุ่มเป้าหมาย`,
            value: response.data.reduce((acc, cur) => acc + cur.target, 0).toLocaleString("en-US"),
            unit: "คน",
          },
          {
            name: `นร.${filter.jontype === "exjon" ? "ที่ได้รับทุนเสมอภาค" : "ยากจน"}`,
            value: response.data.reduce((acc, cur) => acc + cur[filter.jontype], 0),
            unit: "คน",
          },
          {
            name: "คิดเป็น",
            value: (
              (response.data.reduce((acc, cur) => acc + cur[filter.jontype], 0) / response.data.reduce((acc, cur) => acc + cur.target, 0)) *
              100
            ).toFixed(2),
            unit: "%",
          },
        ];
        setLegendData({
          ...legend_data,
          title: filter.province_name,
          subtitle: filter.city_name,
          data: data_legend,
        });
      }
    }
  }, [filter, loading, data]);

  const convertMapContent = (filter, data) => {
    let res = [];
    const province = [...new Set(TumbonAddr.map((item) => item.provincename))].map((item) => {
      let obj = { province: item, total: 0, target: 0, popup: "cct" };
      obj[filter.jontype] = 0;
      return obj;
    });
    const city = [...new Set(TumbonAddr.map((item) => item.provincename + "_" + item.cityname))].map((item) => {
      let obj = { province: item.split("_")[0], city: item.split("_")[1], total: 0, target: 0, popup: "cct" };
      obj[filter.jontype] = 0;
      return obj;
    });
    const tumbon = [...new Set(TumbonAddr.map((item) => item.provincename + "_" + item.cityname + "_" + item.tumbonname))].map((item) => {
      let obj = { province: item.split("_")[0], city: item.split("_")[1], tumbon: item.split("_")[2], total: 0, target: 0, popup: "cct" };
      obj[filter.jontype] = 0;
      return obj;
    });
    if (filter.sungkud !== "ทั้งหมด") data = data.filter((item) => item.sungkud === filter.sungkud);
    data.forEach((item) => {
      let total = item.total_student;
      let target = item.total_target;
      let cct = item["total" + "_" + filter.jontype];
      if (filter.educlass !== "ทั้งหมด") {
        target = item[filter.educlass];
        if (item.sungkud === "สพฐ." && filter.educlass === "h") target = 0;
        if (item.sungkud === "กทม." && filter.educlass === "h") target = 0;
        if (item.sungkud === "อปท." && filter.educlass === "h") target = 0;
        // if (item.sungkud === 'ตชด.' && (filter.educlass === 'h')) target = 0
        if (item.sungkud === "พศ." && (filter.educlass === "k" || filter.educlass === "m" || filter.educlass === "h")) target = 0;
        cct = item[filter.educlass + "_" + filter.jontype];
      }
      if (filter.view === "country") {
        let index = province.findIndex((ele) => ele.province === item.province_name);
        if (index !== -1) {
          !province[index]["total"] ? (province[index]["total"] = total) : (province[index]["total"] += total);
          !province[index]["target"] ? (province[index]["target"] = target) : (province[index]["target"] += target);
          !province[index][filter.jontype] ? (province[index][filter.jontype] = cct) : (province[index][filter.jontype] += cct);
        }
        res = province;
      } else if (filter.view === "province") {
        let index = city.findIndex((ele) => ele.province === item.province_name && ele.city === item.city_name);
        if (index !== -1) {
          !city[index]["total"] ? (city[index]["total"] = total) : (city[index]["total"] += total);
          !city[index]["target"] ? (city[index]["target"] = target) : (city[index]["target"] += target);
          !city[index][filter.jontype] ? (city[index][filter.jontype] = cct) : (city[index][filter.jontype] += cct);
        }
        res = city;
      } else if (filter.view === "city") {
        let index = tumbon.findIndex((ele) => ele.province === item.province_name && ele.city === item.city_name && ele.tumbon === item.tumbon_name);
        if (index !== -1) {
          !tumbon[index]["total"] ? (tumbon[index]["total"] = total) : (tumbon[index]["total"] += total);
          !tumbon[index]["target"] ? (tumbon[index]["target"] = target) : (tumbon[index]["target"] += target);
          !tumbon[index][filter.jontype] ? (tumbon[index][filter.jontype] = cct) : (tumbon[index][filter.jontype] += cct);
        }
        res = tumbon;
      } else if (filter.view === "tumbon") {
        let index = tumbon.findIndex((ele) => ele.province === item.province_name && ele.city === item.city_name && ele.tumbon === item.tumbon_name);
        if (index !== -1) {
          !tumbon[index]["total"] ? (tumbon[index]["total"] = total) : (tumbon[index]["total"] += total);
          !tumbon[index]["target"] ? (tumbon[index]["target"] = target) : (tumbon[index]["target"] += target);
          !tumbon[index][filter.jontype] ? (tumbon[index][filter.jontype] = cct) : (tumbon[index][filter.jontype] += cct);
        }
        res = tumbon;
      }
    });
    return res;
  };

  const onProcessData = (filter, data) => {
    const _genColorRange = (color_set, value) => {
      color_set = color_set.sort((a, b) => b.value - a.value);
      let color = "";
      for (let i in color_set) {
        if (value > color_set[i].value) {
          color = color_set[i].color;
          break;
        }
      }
      return color;
    };

    let result = [];
    data.forEach((element) => {
      if (filter.province && filter.province !== "ทั้งหมด" && !filter.city && !filter.tumbon) {
        if (filter.province === element.province) {
          result.push(element);
        }
      } else if (filter.province && filter.city && !filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city) {
          result.push(element);
        }
      } else if (filter.province && filter.city && filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city && filter.tumbon === element.tumbon) {
          result.push(element);
        }
      } else {
        result.push(element);
      }
    });

    result.map((element) => {
      if (!isFinite(element[filter.jontype] / element["total"])) {
        element["percent"] = 0;
      } else {
        element["percent"] = (element[filter.jontype] / result.map((item) => item[filter.jontype]).reduce((a, b) => a + b, 0)) * 100;
      }
      return element;
    });

    let data_color = [
      {
        color: border_color[0],
        value: Calculate.Percentile(
          90,
          result.map((item) => item[filter.jontype])
        ),
        count: 0,
        text: `มาก`,
      },
      {
        color: border_color[1],
        value: Calculate.Percentile(
          50,
          result.map((item) => item[filter.jontype])
        ),
        count: 0,
        text: `ปานกลาง`,
      },
      {
        color: border_color[2],
        value: Calculate.Percentile(
          10,
          result.map((item) => item[filter.jontype])
        ),
        count: 0,
        text: `ปานกลาง`,
      },
      { color: border_color[3], value: 0, count: 0, text: `น้อย` },
      { color: border_color[4], value: -100, count: 0, text: `น้อย` },
    ];

    result.forEach((element) => {
      element["color"] = _genColorRange(data_color, element[filter.jontype]);
      // element['opacity'] = 0.5
      element["percent"] = parseFloat(element["percent"].toFixed(2));
      element["percent_cct"] = parseFloat(((element[filter.jontype] / element["target"]) * 100).toFixed(2));
    });

    let res = { data: result, notic: data_color };
    return res;
  };

  const onclickBorder = (layer, filterBorder) => {
    if (layer === "tumbon") return;
    if (!is_Click.current) {
      is_Click.current = true;
      let _filter = { ...filter };
      if (layer === "province" && filterBorder.province) {
        _filter["view"] = "province";
        _filter["year"] = filterBorder.year;
        _filter["province_name"] = filterBorder.province;
        _filter["city_name"] = null;
        _filter["tumbon_name"] = null;
      } else if (layer === "city" && filterBorder.city) {
        _filter["view"] = "city";
        _filter["year"] = filterBorder.year;
        _filter["province_name"] = filterBorder.province;
        _filter["city_name"] = filterBorder.city;
        _filter["tumbon_name"] = null;
      }
      setFilter(_filter);
      setTimeout(() => {
        is_Click.current = false;
      }, 1000);
    }
  };

  const onClickMarker = async (property) => {
    if (!is_Click.current) {
      is_Click.current = true;
      let school_data = await api.getSchool({ year: `${filter.year[2]}${filter.year[3]}`, semester: filter.semester, hostid: property.hostid });
      setModalSchool({
        visible: true,
        resource: "school",
        ...property,
        ...school_data.res,
      });
      setTimeout(() => {
        is_Click.current = false;
      }, 1000);
    }
  };

  const onBack = () => {
    let _filter = { ...filter };
    if (_filter.view === _filter.minView) return;
    if (_filter.view === "province") {
      _filter["view"] = "country";
      _filter["province_name"] = "ทั้งหมด";
      _filter["city_name"] = null;
      _filter["tumbon_name"] = null;
    } else if (_filter.view === "city") {
      _filter["view"] = "province";
      _filter["city_name"] = null;
      _filter["tumbon_name"] = null;
    }
    setFilter(_filter);
  };

  const updateFilter = (res) => {
    return setFilter({ ...res });
  };

  let tab_content = [
    {
      title: "ภาพรวม",
      content: <Overview data={dataTab.overview} props={props.pageContext} filter={filter} setFilter={updateFilter} />,
    },
    {
      title: "ดูตามภาคเรียน",
      content: <Semesterview data={dataTab.semesterview} props={props.pageContext} filter={filter} setFilter={updateFilter} />,
    },
    {
      title: "ดูตามพื้นที่",
      content: (
        <MapAreaView
          data={dataTab.areaview}
          filter={filter}
          setFilter={updateFilter}
          exportXLSXbyProvince={(event) => {
            setModalExport({ visible: true, data: { year: "all", sungkud: "all" } });
          }}
          exportXLSXbySchool={(event) => {
            setModalExport({ visible: true });
          }}
        />
      ),
    },
    {
      title: "ดูตามสังกัด",
      content: <SungkudView data={dataTab.sungkudview} filter={filter} setFilter={updateFilter} />,
    },
    {
      title: "จน 100 %",
      content: <Jon100View data={dataTab.jon100view} filter={filter} setFilter={updateFilter} />,
    },
  ];

  const handleHref = async (filter_href, category) => {
    let name = "";
    if (category === "province") {
      if (filter_href?.year === "all") {
        name = "นักเรียนยากจน_ระดับจังหวัด_ทั้งหมด.xlsx";
      } else {
        name = `นักเรียนยากจน_ระดับจังหวัด_${filter_href?.sungkud}_${filter_href?.year}.xlsx`;
      }
    } else {
      if (filter_href?.year === "all") {
        name = "นักเรียนยากจน_ระดับโรงเรียน_ทั้งหมด.xlsx";
      } else {
        name = `นักเรียนยากจน_ระดับโรงเรียน_${filter_href?.sungkud}_${filter_href?.year}.xlsx`;
      }
    }
    await api.downloadFileExcel(name);
  };

  if (loading) {
    return (
      <Layoutas Role={["partner", "eef", "partner_province"]} isHideFooter>
        <div className="relative overflow-hidden">
          <div className="w-[100vw] h-[100vh] flex flex-col justify-center items-center">
            <div className="text-center" role="status">
              <svg
                className="inline mr-2 w-32 h-32 text-gray-200 animate-spin dark:text-gray-200 fill-[#038967]"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
            <div className="text-2xl text-[#038967] font-bold text-center mt-4"> Loading ...</div>
          </div>
        </div>
      </Layoutas>
    );
  }
  return (
    <Layoutas Role={["partner", "eef", "partner_province"]} isHideFooter>
      <Modal
        width={"1000px"}
        bodyStyle={{ borderRadius: 24 }}
        title={null}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        visible={modalSchool.visible}
        centered={true}
        CancelText={"ปิด"}
        onCancel={() => {
          setModalSchool({ visible: false });
        }}
      >
        <div className="text-3xl">
          โรงเรียน{modalSchool?.hostname} <span className="text-xl"></span>
        </div>
        <div className="text-xl">
          ที่อยู่ ตำบล{modalSchool?.tumbon_name} อำเภอ{modalSchool?.city_name} จังหวัด{modalSchool?.province_name}
        </div>
        <Divider style={{ margin: 8 }} />
        <div className="text-3xl m-2">ได้รับทุนจากโครงการทุนเสมอภาค {modalSchool?.total_exjon} คน</div>
        <Divider style={{ margin: 4 }} />

        <div className="grid grid-cols-12 gap-4 ">
          <div className="col-span-12 lg:col-span-6 flex-col">
            <div className="relative w-full ">
              <div className="w-full flex flex-col rounded-lg p-2">
                <div className="text-xl text-green-500 ">ข้อมูลพื้นฐาน</div>
                <div className="text-lg ">สังกัด : {modalSchool?.sungkud} </div>
                <div className="text-lg ">ขนาดโรงเรียน : {modalSchool?.school_size} </div>
                <div className="text-lg ">ลักษณะภูมิประเทศ : {MAP_TEXT[modalSchool?.geographic_location]} </div>
                <div className="text-lg ">
                  ระดับชั้นที่เปิดสอน : {modalSchool?.lowest_classes} - {modalSchool?.highest_classes}
                </div>
                <Divider style={{ margin: 4 }} />
                <div className="text-xl text-green-500 mt-8">ข้อมูลนักเรียน</div>
                <div className="text-lg ">
                  จำนวนนักเรียนทั้งหมด : {(modalSchool?.k + modalSchool?.p + modalSchool?.m + modalSchool?.h)?.toLocaleString("en-US")} คน{" "}
                </div>
                <div className="text-lg ">อนุบาล : {modalSchool?.k?.toLocaleString("en-US")} คน</div>
                <div className="text-lg ">ประถมศึกษา : {modalSchool?.p?.toLocaleString("en-US")} คน</div>
                <div className="text-lg ">มัธยมศึกษาตอนต้น : {modalSchool?.m?.toLocaleString("en-US")} คน</div>
                <div className="text-lg ">มัธยมศึกษาตอนปลาย : {modalSchool?.h?.toLocaleString("en-US")} คน</div>
              </div>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-6 flex-col">
            <div className="relative w-full ">
              <div className="w-full flex flex-col rounded-lg p-2">
                <div className="text-xl text-green-500 ">ข้อมูลทรัพยากร</div>
                <div className="text-lg ">ไฟฟ้า : {modalSchool?.school_has_electricity ? "โรงเรียนมีไฟฟ้าใช้" : "โรงเรียนไม่มีไฟฟ้าใช้"} </div>
                <div className="text-lg ">แหล่งน้ำ : {modalSchool?.water_source} </div>
                <Divider style={{ margin: 4 }} />
                <div className="text-xl text-green-500 ">ข้อมูลติดต่อ</div>
                <div className="text-lg ">email : {modalSchool?.email} </div>
                <div className="text-lg ">website : {modalSchool?.website} </div>
                <a
                  target="_blank"
                  href={`https://www.google.com/maps/search/?api=1&query=${modalSchool?.lat},${modalSchool?.lng}`}
                  className="text-lg "
                >
                  นำทางไปยัง Google Maps
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        width={"500px"}
        bodyStyle={{ borderRadius: 24, paddingBottom: 4 }}
        title={"ดาวน์โหลดข้อมูล"}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        visible={modalExport.visible}
        centered={true}
        CancelText={"ปิด"}
        onCancel={() => {
          setModalExport({ visible: false });
        }}
      >
        <div className="flex flex-row">
          <div>
            <h5 className="text-sm m-0 ml-[9px]">ภาคเรียนที่: </h5>
            <Select
              className="new-design-select"
              placeholder="ภาคเรียน"
              value={`${modalExport?.data?.year}`}
              onChange={(val) => {
                modalExport.data.year = val;
                if (val === "all") {
                  modalExport.data.sungkud = "all";
                } else {
                  modalExport.data.sungkud = "สพฐ";
                }
                setModalExport({ ...modalExport });
              }}
              style={{ width: 120, margin: 4, fontFamily: "Kanit" }}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              <Option value={"all"}>{"ทั้งหมด"}</Option>
              <Option value={"25671"}>{"1/2567"}</Option>
              <Option value={"25662"}>{"2/2566"}</Option>
              <Option value={"25661"}>{"1/2566"}</Option>
              <Option value={"25652"}>{"2/2565"}</Option>
              <Option value={"25651"}>{"1/2565"}</Option>
              <Option value={"25642"}>{"2/2564"}</Option>
              <Option value={"25641"}>{"1/2564"}</Option>
              <Option value={"25632"}>{"2/2563"}</Option>
              <Option value={"25631"}>{"1/2563"}</Option>
              <Option value={"25622"}>{"2/2562"}</Option>
              <Option value={"25621"}>{"1/2562"}</Option>
              <Option value={"25612"}>{"2/2561"}</Option>
            </Select>
          </div>

          <div>
            <h5 className="text-sm m-0 ml-[9px]">สังกัด: </h5>
            <Select
              className="new-design-select"
              placeholder="ภาคเรียน"
              value={`${modalExport?.data?.sungkud}`}
              disabled={modalExport?.data?.sungkud === "all"}
              onChange={(val) => {
                modalExport.data.sungkud = val;
                setModalExport({ ...modalExport });
              }}
              style={{ width: 120, margin: 4, fontFamily: "Kanit" }}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {modalExport?.data?.sungkud === "all" && <Option value={"all"}>{"ทั้งหมด"}</Option>}
              <Option value={"สพฐ"}>{"สพฐ."}</Option>
              <Option value={"อปท"}>{"อปท."}</Option>
              <Option value={"ตชด"}>{"ตชด."}</Option>
              {["25632", "25641", "25642", "25651", "25652", "25661", "25662", "25671"].includes(modalExport?.data?.year) && (
                <Option value={"พศ"}>{"พศ."}</Option>
              )}
              {["25642", "25651", "25652", "25661", "25662", "25671"].includes(modalExport?.data?.year) && <Option value={"สช"}>{"สช."}</Option>}
              {["25652", "25661", "25662", "25671"].includes(modalExport?.data?.year) && <Option value={"กทม"}>{"กทม."}</Option>}
            </Select>
          </div>
        </div>

        <Button
          type="primary"
          shape="round"
          icon={<FileExcelOutlined />}
          size={"large"}
          style={{ margin: "10px" }}
          onClick={() => handleHref(modalExport?.data, "province")}
        >
          ระดับจังหวัด
        </Button>

        <Button type="primary" shape="round" icon={<FileExcelOutlined />} size={"large"} onClick={() => handleHref(modalExport?.data, "school")}>
          ระดับโรงเรียน
        </Button>
      </Modal>

      <div className="relative overflow-hidden">
        <div className="w-full gl:w-full h-screen lg:h-screen">
          <Mapbox
            id={"map-cct"}
            filter={filter}
            data={data_map}
            className="fullmap-cct-container"
            style={"border_basic"}
            openSideTab={open}
            notic_title={"จำนวนโรงเรียนในประเทศไทย"}
            onclickBorder={onclickBorder}
            onClickMarker={onClickMarker}
          />
          <div className="absolute top-[90px] left-2 z-1">
            <LegendCard {...legend_data} />
            <div className="justify-left mt-2 mx-auto">
              {filter.view !== filter.minView ? (
                <button
                  class="bg-[#fb923c] hover:bg-[#f97316] border-8 border-[#ffedd5] text-white font-bold p-2 rounded-full"
                  onClick={() => onBack()}
                >
                  <ArrowBackIcon sx={{ fontSize: 18, color: "white" }} /> ย้อนกลับ
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="absolute bottom-[50px] md:bottom-[10px] left-[100px] z-1 flex">
            <div className="h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3">
              <span className="font-bold center mx-1 mb-2">สัดส่วนนักเรียนที่ได้รับทุน</span>
              <div className="flex">
                <div className="flex flex-col">
                  <div class={`h-3 w-8 md:w-16 bg-[#1b5e20]`}></div>
                  <div class="font-bold text-left">มาก</div>
                </div>
                <div className="flex flex-col">
                  <div class={`h-3 w-8 md:w-16 bg-[#388e3c]`}></div>
                </div>
                <div className="flex flex-col">
                  <div class={`h-3 w-8 md:w-16 bg-[#66bb6a]`}></div>
                </div>
                <div className="flex flex-col">
                  <div class={`h-3 w-8 md:w-16 bg-[#a5d6a7]`}></div>
                  <div class="font-bold text-right">น้อย</div>
                </div>
                <div className="ml-4  flex flex-col">
                  <div class={`h-3 w-8 md:w-16 bg-[#94a3b8]`}></div>
                  <div class="font-bold text-center">ไม่มีข้อมูล</div>
                </div>
              </div>
            </div>
          </div>

          {filter.view === "city" ? (
            <div className="absolute bottom-[10px] left-[470px] z-1">
              <div className="h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3">
                <span className="font-bold center mx-1 mb-2">ประเภทสถานศึกษาตามสังกัด</span>
                <div class="flex flex-row">
                  <div class="flex flex-row text-center mr-2">
                    <img src={marker_obec} style={{ width: "20px", height: "20px", margin: "auto" }} />
                    <span className="center m-auto">สพฐ.</span>
                  </div>
                  <div class="flex flex-row text-center mr-2">
                    <img src={marker_dla} style={{ width: "20px", height: "20px", margin: "auto" }} />
                    <span className="center m-auto">อปท.</span>
                  </div>
                  <div class="flex flex-row text-center mr-2">
                    <img src={marker_bpp} style={{ width: "20px", height: "20px", margin: "auto" }} />
                    <span className="center m-auto">ตชด.</span>
                  </div>
                  <div class="flex flex-row text-center mr-2">
                    <img src={marker_onab} style={{ width: "20px", height: "20px", margin: "auto" }} />
                    <span className="center m-auto">พศ.</span>
                  </div>
                  <div class="flex flex-row text-center mr-2">
                    <img src={marker_opec} style={{ width: "20px", height: "20px", margin: "auto" }} />
                    <span className="center m-auto">สช.</span>
                  </div>
                  <div class="flex flex-row text-center mr-2">
                    <img src={marker_bkk} style={{ width: "20px", height: "20px", margin: "auto" }} />
                    <span className="center m-auto">กทม.</span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {filter.jontype === "jon100" ? (
            <div className="absolute bottom-[10px] left-[470px] z-1">
              <div className="h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3">
                <span className="font-bold center mx-1 mb-2">ประเภทสถานศึกษา</span>
                <div class="flex flex-row">
                  <div class="flex flex-row text-center mr-2">
                    <img src={marker_jon100} style={{ width: "20px", height: "20px", margin: "auto" }} />
                    <span className="center m-auto">โรงเรียนยากจน 100%</span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        <Sidetab
          title={`ทุนเสมอภาค`}
          tab={tab_content}
          titleRef={
            <div>
              <span>*โรงเรียนยากจน 100% คิดจากนักเรียนที่ได้รับทุนปัจจัยพื้นฐานทั้งโรงเรียน</span>
              <br />
              <span>
                *ทุนเสมอภาค ข้อมูลจำนวนเด็กนักเรียนที่ได้รับความช่วยเหลือใน 6 สังกัด ได้แก่ สพฐ. อปท. ตชด. พศ. สช. และ กทม. ภาคเรียนที่ 1/2562 -
                1/2567
              </span>
            </div>
          }
          subjectTitleRef="*ประมวลผล ณ เดือนธันวาคม 2567"
          open={open}
          tabvalue={tabvalue}
          setOpen={setOpen}
          handleChangeTab={(event, res) => handleChangeTab(event, res, tabvalue)}
        />
      </div>
    </Layoutas>
  );
};

export default FullMap;
