import React, { useEffect, useRef } from "react";
import axios from "axios";
import marker_obec from "/images/marker/dot-obec.svg";
import marker_dla from "/images/marker/dot-dla.svg";
import marker_bpp from "/images/marker/dot-bpp.svg";
import marker_buddha from "/images/marker/dot-onab.svg";
import marker_opec from "/images/marker/dot-opec.svg";
import marker_other from "/images/marker/dot-other.svg";

let API_KEY = "4a39c17413c38a50956b247ee33239b3";
const DISTANCE = 20; //km

const LongdoMap = (props) => {
  let [map, setMap] = React.useState(null);
  let isScreenSizeGL = React.useRef(false);
  // let [suggest, setSuggest] = React.useState(null)
  // let [search, setSearch] = React.useState(null)
  // console.log(props)

  useEffect(() => {
    const existingScript = document.getElementById("longdoMapScript");
    isScreenSizeGL.current = document.documentElement.clientWidth < 1024 ? true : false;
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = `https://api.longdo.com/map/?key=${API_KEY}`;
      script.id = "longdoMapScript";
      document.body.appendChild(script);

      script.onload = () => {
        map = new window.longdo.Map({
          placeholder: document.getElementById(props.id),
          // language: 'en'
        });

        map.location(
          {
            lon: isScreenSizeGL.current ? 100.523186 : 110.523186,
            lat: 14.136717,
          },
          true
        );
        map.zoom(isScreenSizeGL.current ? 5 : 6.2);
        map.Ui.DPad.visible(false);
        map.Ui.Zoombar.visible(false);
        map.Ui.Toolbar.visible(false);
        map.Ui.Crosshair.visible(false);

        map.Ui.Scale.visible(true);
        map.Ui.Fullscreen.visible(true);
        map.Ui.LayerSelector.visible(true);
        setMap(map);
      };
    }
  }, []);

  useEffect(() => {
    if (map && props) {
      if (props.schoolmarker.length > 0 || props.schoolsearhmarker.length > 0) {
        map.Event.bind("overlayClick", function (overlay) {
          onclickMarker(overlay, props);
        });
      }
    }
  }, [map, props.schoolmarker, props.schoolsearhmarker]);

  const onclickMarker = (overlay, props) => {
    if (overlay.title) {
      map.Overlays.bounce(overlay);
      let onclicklist = [];
      if (props.tabsearch === 0) {
        onclicklist = props.schoolmarker;
      } else {
        onclicklist = props.schoolsearhmarker;
      }
      let index_ = onclicklist.findIndex((item) => item.hostid == overlay.title.split("_")[1]);
      if (index_ !== -1) {
        props.onClickMarker(onclicklist[index_]);
      }
    }
  };

  useEffect(async () => {
    const existingScript = document.getElementById("longdoMapScript");
    if (existingScript && props.onsearch.value && props.onsearch.value !== "") {
      let result = await onSearch(props.onsearch.value);
      props.onSearchResult(result);
    }
  }, [props.onsearch]);

  // useEffect(() => {
  //   const existingScript = document.getElementById('longdoMapScript');
  //   if (existingScript && props.searchtmp && props.searchtmp !== '') {
  //     onSuggest(props.searchtmp)
  //   }
  // }, [props.searchtmp]);

  useEffect(() => {
    const existingScript = document.getElementById("longdoMapScript");
    if (existingScript && props.marker.length > 0) {
      _clearAllMarker();
      _createMainMarker(props.marker);
      _createMarkerDistance(props.marker);
    }
  }, [props.marker, props.schoolmarker]);

  useEffect(() => {
    const existingScript = document.getElementById("longdoMapScript");
    if (existingScript && props.schoolsearhmarker.length > 0) {
      _clearAllMarker();
      _createMarker(props.schoolsearhmarker);
    }
  }, [props.schoolsearhmarker]);

  useEffect(async () => {
    const existingScript = document.getElementById("longdoMapScript");
    if (existingScript && props.stopBounceMarker) {
      if (map) if (map.Overlays) map.Overlays.bounce(null);
    }
  }, [props.stopBounceMarker]);

  // const onSuggest = async (value) => {
  //   if (value && value !== '') {
  //     try {
  //       const response = await axios.get(`https://search.longdo.com/mapsearch/json/suggest?keyword=${value}&area=10&span=100km&limit=20&key=${API_KEY}`)
  //       setSuggest({ value: response.data.data, error: false })
  //     } catch (error) {
  //       setSuggest({ value: null, error: false })
  //     }
  //   }
  // }

  const onSearch = async (value) => {
    if (value && value !== "") {
      try {
        const response = await axios.get(`https://search.longdo.com/mapsearch/json/search?keyword=${value}&locale=th&limit=20&key=${API_KEY}`);
        return { value: response.data.data, error: false };
      } catch (error) {
        return { value: null, error: false };
      }
    }
  };

  const _createMainMarker = (dataset) => {
    map.Overlays.clear();
    dataset.forEach((element) => {
      if (element.lon && element.lat) {
        var marker = new window.longdo.Marker({ lon: element.lon, lat: element.lat }, { clickable: false });
        map.Overlays.add(marker);
        map.location(
          {
            lon: isScreenSizeGL.current ? element.lon : element.lon + 0.05,
            lat: element.lat,
          },
          true
        );
        map.zoom(isScreenSizeGL.current ? 10 : 11);
      }
    });
  };

  const _createMarker = (data) => {
    let school_in_distance = [];
    data.forEach((element) => {
      school_in_distance.push({ ...element });
      let locat = "";
      if (element.sungkud === "สพฐ.") {
        locat = marker_obec;
      } else if (element.sungkud === "อปท.") {
        locat = marker_dla;
      } else if (element.sungkud === "ตชด.") {
        locat = marker_bpp;
      } else if (element.sungkud === "พศ.") {
        locat = marker_buddha;
      } else if (element.sungkud === "สช.") {
        locat = marker_opec;
      } else {
        locat = marker_other;
      }
      var marker = new window.longdo.Marker(
        { lon: element.lng, lat: element.lat },
        {
          title: `รร.${element.hostname.replace("รร.", "")}_${element.hostid}`,
          icon: {
            url: locat,
            offset: { x: 16, y: 32 },
            size: { width: 20, height: 20 },
          },
          draggable: false,
          // detail: schooldata.host
        }
      );
      map.Overlays.add(marker);
    });
  };

  const _createMarkerDistance = (dataset) => {
    let school_in_distance = [];
    dataset.forEach((main) => {
      props.schoolmarker.forEach((element) => {
        let distance = findDisplacement(main.lon, main.lat, element.lng, element.lat);
        if (distance <= DISTANCE) {
          school_in_distance.push({
            ...element,
            distance: distance.toFixed(2),
          });
          let locat = "";
          if (element.sungkud === "สพฐ.") {
            locat = marker_obec;
          } else if (element.sungkud === "อปท.") {
            locat = marker_dla;
          } else if (element.sungkud === "ตชด.") {
            locat = marker_bpp;
          } else if (element.sungkud === "พศ.") {
            locat = marker_buddha;
          } else if (element.sungkud === "สช.") {
            locat = marker_opec;
          } else {
            locat = marker_other;
          }
          var marker = new window.longdo.Marker(
            { lon: element.lng, lat: element.lat },
            {
              title: `รร.${element.hostname.replace("รร.", "")}_${element.hostid}`,
              icon: {
                url: locat,
                offset: { x: 16, y: 32 },
                size: { width: 20, height: 20 },
              },
              draggable: false,
              // detail: schooldata.host
            }
          );
          map.Overlays.add(marker);
        }
      });
    });
    props.getSchoolInDistance(school_in_distance);
  };

  const _clearMarker = (marker) => {
    marker.forEach((element) => {
      map.Overlays.remove(element);
    });
  };

  const _clearAllMarker = () => {
    map.location({ lon: isScreenSizeGL.current ? 100.523186 : 110.523186, lat: 14.136717 }, true);
    map.zoom(isScreenSizeGL.current ? 5 : 6.2);
    map.Overlays.clear();
  };

  const findDisplacement = (lng1, lat1, lng2, lat2) => {
    var R = 6371; //kilometers
    var Rlat1 = degrees_to_radians(lat1);
    var Rlat2 = degrees_to_radians(lat2);
    var Rlatdelta = degrees_to_radians(lat2 - lat1);
    var Rlngdelta = degrees_to_radians(lng2 - lng1);

    var a = Math.sin(Rlatdelta / 2) * Math.sin(Rlatdelta / 2) + Math.cos(Rlat1) * Math.cos(Rlat2) * Math.sin(Rlngdelta / 2) * Math.sin(Rlngdelta / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    var d = R * c;
    return d;
  };

  const degrees_to_radians = (degrees) => {
    return degrees * (Math.PI / 180);
  };

  return <div id={props.id} style={{ width: "100%", height: "100%" }}></div>;
};

export default LongdoMap;
