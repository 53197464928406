import React, { useState, useEffect, useRef } from "react";
import Layout from "../components/Layout";
import { navigate } from "gatsby";
import Mapbox from "../mapbox";
import LegendCard from "../mapbox/LegendCard";
import Calculate from "../util/calculate";

import { Select } from "antd";
import "./index.css";
import ImageBG from "/images/oosc-bg.jpg";

import ImageTSQP1 from "/images/tsqp-1.png";
import ImageTSQP2 from "/images/tsqp-2.png";
import ImageTSQP3 from "/images/tsqp-3.png";
import ImageTSQP4 from "/images/tsqp-4.png";
import ImageTSQP5 from "/images/tsqp-5.png";
import ImageTSQP6 from "/images/tsqp-6.png";
import ImageTSQP7 from "/images/tsqp-7.png";
import ImageTSQPC1 from "/images/TSPQ-c1.png";
import ImageTSQPC2 from "/images/TSPQ-c2.png";
import Highcharts from "highcharts";
import HighchartsItemSeries from "highcharts/modules/item-series";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import marker_obec from "/images/marker/dot-obec.svg";
import marker_bpp from "/images/marker/dot-bpp.svg";
import marker_onab from "/images/marker/dot-onab.svg";

import TumbonAddr from "../util/TumbonAddr";
import region from "../util/region";
import RefDataDate from "../components/RefDataDate";
import ENG_Addrees from '../util/ENG_Addrees'
import api from "../util/api"
import getProvinceName from '../util/getProvinceName'

const { Option } = Select;

if (typeof Highcharts === "object") {
  HighchartsItemSeries(Highcharts);
}

const PageTSQP = (props) => {
  const [initData, setInitData] = useState(null)
  const [filter, setFilter] = React.useState({
    minView: 'country',
    view: "country",
    year: "2563",
    region: "ทั้งหมด",
    province_name: "ทั้งหมด",
    city_name: null,
    tumbon_name: null,
    generation: "ทั้งหมด",
    network: "ทั้งหมด",
    sungkud: "ทั้งหมด",
  });
  const [data, setData] = useState({
    province: 0,
    schoolVersion1: 0,
    schoolVersion2: 0,
    max: 0,
    percentversion1: 0,
    percentversion2: 0,
  });
  const [dataFilter, setDataFilter] = useState([]);
  const [data_map, setDataMap] = useState([]);
  const [border_color] = useState([
    "#1b5e20",
    "#388e3c",
    "#66bb6a",
    "#a5d6a7",
    "#94a3b8",
  ]);
  const is_Click = useRef(false);
  const [translate, setTranslate] = useState(null);
  const [lang, setLang] = useState(null);
  const [legend_data, setLegendData] = useState({
    title: "",
    subtitle: "",
    data: [],
    footer: "",
  });

  useEffect(async () => {
    let res = await api.getInitData('tsqp')
    let is_login = api.isLogin().status
    let res_profile = await (is_login ? api.getProfileDb() : null)
    if (res_profile) {
      let y2562 = []
      let y2563 = []
      if (res_profile?.profile?.roles?.includes("partner_province") && api.getActiveRole() === "partner_province") {
        let pid = res_profile?.profile?.profile?.provinceId
        y2562 = res.deepdata["tsqp_2562"].filter(item => item.province === getProvinceName(pid))
        y2563 = res.deepdata["tsqp_2563"].filter(item => item.province === getProvinceName(pid))
        setInitData({
          ...res.deepdata,
          tsqp_2562: res.deepdata.tsqp_2562.filter(item => item.province === getProvinceName(pid)),
          tsqp_2563: res.deepdata.tsqp_2563.filter(item => item.province === getProvinceName(pid))
        })
        setFilter({ ...filter, minView: "province", view: "province", province_name: getProvinceName(pid) })
      } else {
        y2562 = res.deepdata["tsqp_2562"];
        y2563 = res.deepdata["tsqp_2563"];
        setInitData(res.deepdata)
      }
      let allyear = [...y2562, ...y2563];
      let max = Math.max(
        y2562.reduce(
          (acc, cur) =>
            acc +
            (cur.total_kindergarten +
              cur.total_junior +
              cur.total_primary +
              cur.total_high),
          0
        ),
        y2563.reduce(
          (acc, cur) =>
            acc +
            (cur.total_kindergarten +
              cur.total_junior +
              cur.total_primary +
              cur.total_high),
          0
        )
      );
      setData({
        province: [...new Set(allyear.map((item) => item.province))].length,
        schoolVersion1: y2562.length,
        schoolVersion2: y2563.length,
        max: max,
        studentVersion1: y2562.reduce(
          (acc, cur) =>
            acc +
            (cur.total_kindergarten +
              cur.total_junior +
              cur.total_primary +
              cur.total_high),
          0
        ),
        studentVersion2: y2563.reduce(
          (acc, cur) =>
            acc +
            (cur.total_kindergarten +
              cur.total_junior +
              cur.total_primary +
              cur.total_high),
          0
        ),
        percentversion1:
          (y2562.reduce(
            (acc, cur) =>
              acc +
              (cur.total_kindergarten +
                cur.total_junior +
                cur.total_primary +
                cur.total_high),
            0
          ) /
            max) *
          100,
        percentversion2:
          (y2563.reduce(
            (acc, cur) =>
              acc +
              (cur.total_kindergarten +
                cur.total_junior +
                cur.total_primary +
                cur.total_high),
            0
          ) /
            max) *
          100,
      });
    }
  }, []);

  useEffect(() => {
    if (initData) {
      let dataContext = [
        ...initData["tsqp_2563"],
        ...initData["tsqp_2562"],
      ];
      let dataContextFilter = [
        ...initData["tsqp_2563"],
        ...initData["tsqp_2562"],
      ];
      if (filter.generation !== "ทั้งหมด") {
        if (filter.generation === "1") {
          dataContext = initData["tsqp_2562"];
          dataContextFilter = initData["tsqp_2562"];
        } else if (filter.generation === "2") {
          dataContext = initData["tsqp_2563"];
          dataContextFilter = initData["tsqp_2563"];
        }
      }

      let dataContext_by_network = dataContext
      if (filter.network !== "ทั้งหมด") {
        dataContext_by_network = dataContext.filter(item => item.network === filter.network)
      }
      let response = onProcessData(
        {
          province: filter["province_name"],
          city: filter["city_name"],
          tumbon: filter["tumbon_name"],
          layer: filter.view,
        },
        convertMapContent(filter.view, dataContext_by_network)
      );
      setDataFilter(dataContext);
      if (filter.network !== "ทั้งหมด") {
        dataContextFilter = dataContextFilter.filter(
          (item) => item.network === filter.network
        );
      }
      if (!filter.province_name || filter.province_name !== "ทั้งหมด") {
        if (filter.city_name !== null) {
          dataContextFilter = [
            ...new Set(
              dataContextFilter.filter(
                (item) =>
                  item.province === filter.province_name &&
                  item.district === filter.city_name
              )
            ),
          ];
        } else {
          dataContextFilter = [
            ...new Set(
              dataContextFilter.filter(
                (item) => item.province === filter.province_name
              )
            ),
          ];
        }
      }
      console.log(response.data)
      setDataMap([
        { id: "border", layer: filter.view, data: response.data },
        {
          id: "school",
          layer: "marker",
          data:
            filter.sungkud === "ทั้งหมด" || filter.sungkud === "สพฐ."
              ? dataContextFilter
                .filter((x) => x.sungkud === "สพฐ.")
                .map((item) => ({ ...item, type: "school" }))
              : [],
          icon: marker_obec,
        },
        {
          id: "school2",
          layer: "marker",
          data:
            filter.sungkud === "ทั้งหมด" || filter.sungkud === "อปท."
              ? dataContextFilter
                .filter((x) => x.sungkud === "อปท.")
                .map((item) => ({ ...item, type: "school" }))
              : [],
          icon: marker_bpp,
        },
        {
          id: "school3",
          layer: "marker",
          data:
            filter.sungkud === "ทั้งหมด" || filter.sungkud === "สช."
              ? dataContextFilter
                .filter((x) => x.sungkud === "สช.")
                .map((item) => ({ ...item, type: "school" }))
              : [],
          icon: marker_onab,
        },
      ]);
      let data_legend = [];

      if (lang === 'EN') {
        data_legend = [
        {
          name: "Number of schools participating in the project",
          value: response.data.reduce((acc, cur) => acc + cur.school_tsqp, 0),
          unit: "schools",
        }]
      } else {
        data_legend = [
        {
          name: "จำนวนโรงเรียนที่ร่วมโครงการ",
          value: response.data.reduce((acc, cur) => acc + cur.school_tsqp, 0),
          unit: "โรงเรียน",
        }]
      }
      setLegendData({
        ...legend_data,
        title: ENG_Addrees(filter.province_name, lang),
        subtitle: ENG_Addrees(filter.city_name, lang),
        data: data_legend,
      });
    }
  }, [filter, lang, initData]);

  const convertMapContent = (layer, data) => {
    let res = [];
    const province = [
      ...new Set(TumbonAddr.map((item) => item.provincename)),
    ].map((item) => {
      return {
        region: region.getRegion(item),
        province: item,
        // student: 0,
        school_tsqp: 0,
      };
    });
    const city = [
      ...new Set(
        TumbonAddr.map((item) => item.provincename + "_" + item.cityname)
      ),
    ].map((item) => {
      return {
        province: item.split("_")[0],
        city: item.split("_")[1],
        // student: 0,
        school_tsqp: 0,
      };
    });
    const tumbon = [
      ...new Set(
        TumbonAddr.map(
          (item) =>
            item.provincename + "_" + item.cityname + "_" + item.tumbonname
        )
      ),
    ].map((item) => {
      return {
        province: item.split("_")[0],
        city: item.split("_")[1],
        tumbon: item.split("_")[2],
        // student: 0,
        school_tsqp: 0,
      };
    });
    let sum = 0
    data.forEach((item) => {
      sum += 1
      let student =
        item.total_kindergarten +
        item.total_junior +
        item.total_primary +
        item.total_high;
      let school = 1;
      if (layer === "country") {
        let index = province.findIndex((ele) => ele.province === item.province);
        if (index !== -1) {
          // !province[index]["student"]
          //   ? (province[index]["student"] = student)
          //   : (province[index]["student"] += student);
          !province[index]["school_tsqp"]
            ? (province[index]["school_tsqp"] = school)
            : (province[index]["school_tsqp"] += school);
        }
        res = province;
      } else if (layer === "province") {
        let index = city.findIndex(
          (ele) => ele.province === item.province && ele.city === item.district
        );
        if (index !== -1) {
          // !city[index]["student"]
          //   ? (city[index]["student"] = student)
          //   : (city[index]["student"] += student);
          !city[index]["school_tsqp"]
            ? (city[index]["school_tsqp"] = school)
            : (city[index]["school_tsqp"] += school);
        }
        res = city;
      } else if (layer === "city") {
        let index = tumbon.findIndex(
          (ele) =>
            ele.province === item.province &&
            ele.city === item.district &&
            ele.tumbon ===
            (item.province_id === "10"
              ? "แขวง" + item.subdistrict
              : item.subdistrict)
        );
        if (index !== -1) {
          // !tumbon[index]["student"]
          //   ? (tumbon[index]["student"] = student)
          //   : (tumbon[index]["student"] += student);
          !tumbon[index]["school_tsqp"]
            ? (tumbon[index]["school_tsqp"] = school)
            : (tumbon[index]["school_tsqp"] += school);
        }
        res = tumbon;
      } else if (layer === "tumbon") {
        let index = tumbon.findIndex(
          (ele) =>
            ele.province === item.province &&
            ele.city === item.district &&
            ele.tumbon ===
            (item.province_id === "10"
              ? "แขวง" + item.subdistrict
              : item.subdistrict)
        );
        if (index !== -1) {
          // !tumbon[index]["student"]
          //   ? (tumbon[index]["student"] = student)
          //   : (tumbon[index]["total"] += student);
          !tumbon[index]["school_tsqp"]
            ? (tumbon[index]["school_tsqp"] = school)
            : (tumbon[index]["school_tsqp"] += school);
        }
        res = tumbon;
      }
    });
    if (filter.region !== "ทั้งหมด" && filter.view === "country")
      res = res.filter((item) => item.region === filter.region);
    return res;
  };

  const onProcessData = (filter, data) => {
    const _genColorRange = (color_set, value) => {
      color_set = color_set.sort((a, b) => b.value - a.value);
      let color = "";
      for (let i in color_set) {
        if (value > color_set[i].value) {
          color = color_set[i].color;
          break;
        }
      }
      return color;
    };

    let result = [];
    data.forEach((element) => {
      if (
        filter.province &&
        filter.province !== "ทั้งหมด" &&
        !filter.city &&
        !filter.tumbon
      ) {
        if (filter.province === element.province) {
          result.push(element);
        }
      } else if (filter.province && filter.city && !filter.tumbon) {
        if (
          filter.province === element.province &&
          filter.city === element.city
        ) {
          result.push(element);
        }
      } else if (filter.province && filter.city && filter.tumbon) {
        if (
          filter.province === element.province &&
          filter.city === element.city &&
          filter.tumbon === element.tumbon
        ) {
          result.push(element);
        }
      } else {
        result.push(element);
      }
    });

    result.map((element) => {
      if (!isFinite(element["school_tsqp"] / element["school_tsqp"])) {
        element["percent"] = 0;
      } else {
        element["percent"] =
          (element["school_tsqp"] /
            result.map((item) => item.school_tsqp).reduce((a, b) => a + b, 0)) *
          100;
      }
      return element;
    });

    let data_color = [
      {
        color: border_color[0],
        value: Calculate.Percentile(
          90,
          result.map((item) => item.school_tsqp)
        ),
        count: 0,
        text: `มาก`,
      },
      {
        color: border_color[1],
        value: Calculate.Percentile(
          50,
          result.map((item) => item.school_tsqp)
        ),
        count: 0,
        text: `ปานกลาง`,
      },
      {
        color: border_color[2],
        value: Calculate.Percentile(
          10,
          result.map((item) => item.school_tsqp)
        ),
        count: 0,
        text: `ปานกลาง`,
      },
      { color: border_color[3], value: 0, count: 0, text: `น้อย` },
      { color: border_color[4], value: -100, count: 0, text: `น้อย` },
    ];

    result.forEach((element) => {
      element["color"] = _genColorRange(data_color, element.school_tsqp);
      // element['opacity'] = 0.5
      element["percent"] = parseFloat(element["percent"].toFixed(2));
    });

    let res = { data: result, notic: data_color };
    return res;
  };

  const onclickBorder = (layer, filterBorder) => {
    if (layer === "tumbon") return;
    if (!is_Click.current) {
      is_Click.current = true;
      let _filter = { ...filter };
      if (layer === "province" && filterBorder.province) {
        _filter["view"] = "province";
        _filter["year"] = filterBorder.year;
        _filter["province_name"] = filterBorder.province;
        _filter["city_name"] = null;
        _filter["tumbon_name"] = null;
      } else if (layer === "city" && filterBorder.city) {
        _filter["view"] = "city";
        _filter["year"] = filterBorder.year;
        _filter["province_name"] = filterBorder.province;
        _filter["city_name"] = filterBorder.city;
        _filter["tumbon_name"] = null;
      }
      setFilter(_filter);
      setTimeout(() => {
        is_Click.current = false;
      }, 1000);
    }
  };

  const onClickMarker = (property) => {
    if (!is_Click.current) {
      is_Click.current = true;
      setTimeout(() => {
        is_Click.current = false;
      }, 1000);
    }
  };

  const onBack = () => {
    let _filter = { ...filter };
    if (_filter.view === _filter.minView) return;
    if (_filter.view === "province") {
      _filter["view"] = "country";
      _filter["province_name"] = "ทั้งหมด";
      _filter["city_name"] = null;
      _filter["tumbon_name"] = null;
    } else if (_filter.view === "city") {
      _filter["view"] = "province";
      _filter["city_name"] = null;
      _filter["tumbon_name"] = null;
    }
    setFilter(_filter);
  };

  return (
    <Layout
      Role={["public-login", "partner", "eef", 'partner_province']}
      backgroundImage={`url(${ImageBG})`}
      getLang={(translate, lang) => {
        setTranslate(translate)
        setLang(lang)
      }}
    >
      <div>
        <div className="h-[120px]"></div>
        {
          lang !== 'EN' && (
            <h1 className="font-bold text-xl md:text-3xl xl:text-5xl text-center text-white w-full ">
              โครงการโรงเรียนพัฒนาตนเอง (TSQP)
            </h1>
          )
        }
        <h1 className="font-bold text-xl md:text-3xl xl:text-5xl text-center text-white w-full ">
          (Teacher and School Quality Program: TSQP)
        </h1>
        <div className="max-w-[1300px] 2xl:max-w-[1400px]  rounded-3xl m-auto p-8 bg-white bordered">
          <div className="font-bold text-2xl mb-2 text-[#0E3165]">
            {translate?.Project_Description}
          </div>
          <p className="text-lg mb-2 font-baijam">&nbsp;&nbsp;&nbsp;&nbsp;{translate?.TSQP_Project_Description1}</p>
          <p className="text-lg mb-2 font-baijam">&nbsp;&nbsp;&nbsp;&nbsp;{translate?.TSQP_Project_Description2}</p>
          <div className="grid grid-cols-11 gap-2 gap-y-8 w-full mt-8">
            <div className="col-span-12 gap-1">
              <h3 className="font-bold text-2xl mb-2 text-[#0E3165]">
                {translate?.Objectives_Project}
              </h3>
              <div className="px-4 py-1">
                <div className="grid grid-cols-8 lg:grid-cols-9 gap-8 w-full">
                  <div className="col-span-8 md:col-span-4 lg:col-span-3 border-[1px] border-solid mx-auto rounded-xl shadow-xl w-full">
                    <div className="flex flex-col justify-around ">
                      <div className="flex flex-col w-full bg-green-600 rounded-t-xl">
                        <p className="m-0 mt-1 text-center text-lg text-white">
                          {translate?.TSQP_Generate_Interventions_T1}
                        </p>
                        <p className="m-0 text-center text-lg text-white">
                          {translate?.TSQP_Generate_Interventions_T2}
                        </p>

                      </div>
                      <img
                        className="flex self-center w-8/12"
                        src={ImageTSQP1}
                        alt="images"
                      />
                      <ul
                        style={{ listStyleType: "disc", paddingLeft: "22px", paddingRight: "4px" }}
                      >
                        <li>{translate?.TSQP_Generate_Interventions_1}</li>
                        <li>{translate?.TSQP_Generate_Interventions_2}</li>
                        <li>{translate?.TSQP_Generate_Interventions_3}</li>
                        <li>{translate?.TSQP_Generate_Interventions_4}</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-span-8 md:col-span-4 lg:col-span-3 border-[1px] border-solid mx-auto rounded-xl shadow-xl w-full">
                    <div className="flex flex-col justify-around ">
                      <div className="flex flex-col w-full bg-green-600 rounded-t-xl">
                        <p className="m-0 mt-1 text-center text-lg text-white">{translate?.TSQP_Pilot_Incubate_T1}</p>
                        <p className="m-0 text-center text-lg text-white">{translate?.TSQP_Pilot_Incubate_T2}</p>
                      </div>
                      <img
                        className="flex self-center w-8/12"
                        src={ImageTSQP2}
                        alt="images"
                      />
                      <ul
                        style={{ listStyleType: "disc", paddingLeft: "22px", paddingRight: "4px" }}
                      >
                        <li>{translate?.TSQP_Pilot_Incubate_1}</li>
                        <li>{translate?.TSQP_Pilot_Incubate_2}</li>
                        <li>{translate?.TSQP_Pilot_Incubate_3}</li>
                        <li>{translate?.TSQP_Pilot_Incubate_4}</li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-span-8 md:col-span-4 lg:col-span-3 border-[1px] border-solid mx-auto rounded-xl shadow-xl w-full">
                    <div className="flex flex-col justify-around ">
                      <div className="flex flex-col w-full bg-green-600 rounded-t-xl">
                        <p className="m-0 mt-1 text-center text-lg text-white">{translate?.TSQP_Scale_Accelerate_T1}</p>
                        <p className="m-0 text-center text-lg text-white">{translate?.TSQP_Scale_Accelerate_T2}</p>
                      </div>
                      <img
                        className="flex self-center w-8/12"
                        src={ImageTSQP3}
                        alt="images"
                      />
                      <ul
                        style={{ listStyleType: "disc", paddingLeft: "22px", paddingRight: "4px" }}
                      >
                        <li>{translate?.TSQP_Scale_Accelerate_1}</li>
                        <li>{translate?.TSQP_Scale_Accelerate_2}</li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-span-8 md:col-span-4 lg:col-span-3 border-[1px] border-solid mx-auto rounded-xl shadow-xl w-full">
                    <div className="flex flex-col justify-around ">
                      <div className="flex flex-col  w-full bg-green-600 rounded-t-xl">
                        <p className="m-0 mt-1 text-center text-lg text-white">{translate?.TSQP_Monitor_Evaluate_T1}</p>
                        <p className="m-0 text-center text-lg text-white">{translate?.TSQP_Monitor_Evaluate_T2}</p>
                      </div>
                      <img
                        className="flex self-center w-8/12"
                        src={ImageTSQP4}
                        alt="images"
                      />
                      <ul
                        style={{ listStyleType: "disc", paddingLeft: "22px", paddingRight: "4px" }}
                      >
                        <li>{translate?.TSQP_Monitor_Evaluate_1}</li>
                        <li>{translate?.TSQP_Monitor_Evaluate_2}</li>
                        <li>{translate?.TSQP_Monitor_Evaluate_3}</li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-span-8 md:col-span-4 lg:col-span-3 border-[1px] border-solid mx-auto rounded-xl shadow-xl w-full">
                    <div className="flex flex-col justify-around ">
                      <div className="flex flex-col  w-full bg-green-600 rounded-t-xl">
                        <p className="m-0 mx-4 text-center text-lg text-white">{translate?.TSQP_Advocate}</p>
                      </div>
                      <img
                        className="flex self-center w-8/12"
                        src={ImageTSQP5}
                        alt="images"
                      />
                      <ul
                        style={{ listStyleType: "disc", paddingLeft: "22px", paddingRight: "4px" }}
                      >
                        <li>{translate?.TSQP_Advocate_1}</li>
                        <li>{translate?.TSQP_Advocate_2}</li>
                        <li>{translate?.TSQP_Advocate_3}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>



              <div className="px-1 py-1">
                <div className="grid grid-cols-8 lg:grid-cols-9 gap-8 w-full">
                  <div className="col-span-4 lg:col-span-1">

                  </div>


                  <div className="col-span-9 lg:col-span-9 border-[1px] border-solid mx-auto rounded-xl shadow-xl w-full mt-[20px]">
                    <div className="flex flex-col justify-around ">
                      <div className="flex flex-col w-full bg-green-600 rounded-t-xl">
                        <p className="m-0 mt-1 text-center text-lg text-white">{translate?.Six_Operational_Measures}</p>
                      </div>
                      <img
                        className="flex self-center w-10/12 mt-[20px]"
                        src={lang == 'EN' ? ImageTSQPC2 : ImageTSQPC1}
                        alt="images"
                      />

                      <ul
                        style={{ listStyleType: "decimal", paddingLeft: "30px", paddingRight: '30px', paddingTop: '12px' }}
                      >

                        <li className="text-md" >{translate?.Six_Operational_Measures_School_Goal}</li>
                        <p className="font-baijam text-md">
                          {translate?.Six_Operational_Measures_School_Goal_Detail}
                        </p>

                        <li className="text-md" >{translate?.Six_Operational_Measures_PLC}</li>
                        <p className="font-baijam text-md">
                          {translate?.Six_Operational_Measures_PLC_Detail}
                        </p>

                        <li className="text-md" >{translate?.Six_Operational_Measures_INFO}</li>
                        <p className="font-baijam text-md">
                          {translate?.Six_Operational_Measures_INFO_Detail}
                        </p>

                        <li className="text-md" >{translate?.Six_Operational_Classroom_Innovation}</li>
                        <p className="font-baijam text-md">
                          {translate?.Six_Operational_Classroom_Innovation_Detail}
                        </p>

                        <li className="text-md" >{translate?.Six_Operational_Networking}</li>
                        <p className="font-baijam text-md">
                          {translate?.Six_Operational_Networking_Detail}
                        </p>

                        <li className="text-md" >{translate?.Six_Operational_SYSTEM}</li>
                        <p className="font-baijam text-md">
                          {translate?.Six_Operational_SYSTEM_Detail}
                        </p>



                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-span-11">
              <h3 className="font-bold text-2xl mb-2 text-[#0E3165]">
                {translate?.RESULTS_TSQP}
              </h3>
              <div className="grid grid-cols-12 gap-2 w-full">
                <div className="col-span-12 md:col-span-5 lg:col-span-3 ">
                  <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl p-4 h-[270px]">
                    <div className="flex flex-row justify-around ">
                      <div className="flex flex-col m-2 w-full">
                        <div className="flex flex-col ml-4">
                          <div className="font-bold text-xl mb-2 text-center">
                            {translate?.TSQP_coverage_in}
                          </div>
                        </div>
                        <div className="border-t-2 w-full h-[0.5px]"></div>
                        <img
                          className="flex self-center w-[90px] my-3"
                          src={ImageTSQP6}
                          alt="images"
                        />
                        <div className="m-0 text-3xl text-center text-green-600 ">
                          {data.province.toLocaleString("en-US")}
                        </div>
                        <div className="m-0 text-xl text-center">  {translate?.province}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-span-12 md:col-span-5 lg:col-span-4 ">
                  <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl p-4 h-[270px]">
                    <div className="flex flex-row justify-around ">
                      <div className="flex flex-col m-2 w-full">
                        <div className="flex flex-col ml-4">
                          <div className="font-bold text-xl mb-2 text-center">
                            {translate?.TSQP_NO_SCH_IN}
                          </div>
                        </div>
                        <div className="border-t-2 w-full h-[0.5px]"></div>

                        <img
                          className="flex self-center w-[90px] mt-2"
                          src={ImageTSQP7}
                          alt="images"
                        />
                        <div className="flex flex-row w-full mt-2">
                          <div className="flex flex-1 flex-col">
                            <div className="m-0 text-xl text-center">
                              รุ่น 1 (ปี2562)
                            </div>
                            <div className="m-0 text-2xl text-center text-green-600">
                              {data.schoolVersion1}
                            </div>
                            <div className="m-0 text-xl text-center">
                              {translate?.school}
                            </div>
                          </div>
                          <div className="w-[1px] h-[100px] bg-gray-200"></div>
                          <div className="flex flex-1 flex-col">
                            <div className="m-0 text-xl text-center">
                              รุ่น 2 (ปี2563)
                            </div>
                            <div className="m-0 text-2xl text-center text-green-600">
                              {data.schoolVersion2}
                            </div>
                            <div className="m-0 text-xl text-center">
                              {translate?.school}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-span-12 lg:col-span-5">
                  <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-[270px] p-1">
                    <div className="px-6 py-4">
                      <div className="flex flex-col ml-4">
                        <div className="font-bold text-xl mb-2 text-center">
                          {translate?.TSQP_NO_STU}
                        </div>
                      </div>
                      <div className="border-t-2 w-full h-[0.5px]"></div>
                      <div className="flex flex-col">
                        <div className="font-bold text-lg mt-2 text-black-800">
                          {lang === 'EN' ? 'generation 1 (year 2019)' : 'รุ่น 1 (ปี2562)'}
                        </div>
                        <div className="w-full flex flex-row content-center ">
                          <div
                            style={{ width: data.percentversion1 * 0.8 + "%" }}
                            className={`transition-all ease-out duration-1000 h-[22px] bg-[#3D8F4F] relative rounded-md`}
                          />
                          <div className="font-bold text-md ml-2 text-bla ck-800">
                            {`${data.studentVersion1?.toLocaleString("en-US")}`}{" "}
                            คน
                          </div>
                        </div>

                        <div className="font-bold text-lg mt-2 text-black-800">
                          {lang === 'EN' ? 'generation 2 (year 2020)' : 'รุ่น 1 (ปี2563)'}
                        </div>
                        <div className="w-full flex flex-row content-center ">
                          <div
                            style={{ width: data.percentversion2 * 0.8 + "%" }}
                            className={`transition-all ease-out duration-1000 h-[22px] bg-[#80C775] relative rounded-md`}
                          />
                          <div className="font-bold text-md ml-2 text-bla ck-800">
                            {`${data.studentVersion2?.toLocaleString("en-US")}`}{" "}
                            คน
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="max-w rounded-xl overflow-hidden shadow-[0_0px_16px_rgba(17,17,26,0.1)] bg-white w-full mt-10 p-2">
                <header className="text-gray-600 body-font">
                  <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                    <nav className="flex lg:w-1/5 flex-wrap items-center text-base md:ml-auto"></nav>
                    <p className="w-3/5 items-center justify-center font-bold text-2xl mb-2  text-[#048967] text-center">
                      {translate?.TSQP_MAP}
                    </p>
                    <div className="lg:w-1/5 inline-flex lg:justify-end ml-5 lg:ml-0">
                      {
                        lang !== 'EN' &&
                        <button
                          class="inline-flex items-center bg-emerald-500 border py-1 px-3 focus:outline-none hover:bg-emerald-700 rounded-full text-base mt-4 md:mt-0 text-white"
                          onClick={() => navigate("/fullmap-tsqp")}
                        >
                          สำรวจข้อมูลโครงการ
                          <svg
                            fill="none"
                            stroke="currentColor"
                            s
                            troke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            class="w-4 h-4 ml-1"
                            viewBox="0 0 24 24"
                          >
                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                          </svg>
                        </button>
                      }

                    </div>
                  </div>
                </header>

                <div className="w-full h-[1px] bg-gray-300 mb-4"> </div>

                <div className="grid grid-cols-11 gap-4 w-full">
                  <div className="col-span-11 justify-center">
                    <div className="flex flex-col p-0 m-0">
                      < div className="flex flex-col md:flex-row " >
                        <div>
                          <h5 className="text-sm m-0 ml-[9px]">{translate?.generation}: </h5>
                          <Select
                            className="new-design-select"
                            showSearch
                            placeholder="รุ่น"
                            // defaultValue={filter.year}
                            value={filter.generation}
                            style={{ width: 180, margin: 4 }}
                            onChange={(val) => {
                              setFilter({ ...filter, generation: val });
                            }}
                          >
                            <Option value={'ทั้งหมด'}>{ENG_Addrees('ทั้งหมด', lang)}</Option>
                          </Select>
                        </div>
                        <div>
                          <h5 className="text-sm m-0 ml-[9px]">{translate?.network}: </h5>
                          <Select
                            className="new-design-select"
                            showSearch
                            placeholder="เลือกเครือข่าย"
                            defaultValue="ทั้งหมด"
                            value={filter.network}
                            style={{ width: 250, margin: 4 }}
                            onChange={(val) => {
                              setFilter({ ...filter, network: val });
                            }}
                          >
                            <Option value={'ทั้งหมด'}>{ENG_Addrees('ทั้งหมด', lang)}</Option>
                            {[...new Set(dataFilter.map((x) => x.network))]
                              .filter((x) => x !== "ไม่ระบุ")
                              .sort()
                              .map(x => <Option value={x}>{ENG_Addrees(x, lang)}</Option>)
                            }
                          </Select>
                        </div>
                        <div>
                          <h5 className="text-sm m-0 ml-[9px]">{translate?.School_affiliation}: </h5>
                          <Select
                            className="new-design-select"
                            showSearch
                            placeholder="เลือกสังกัด"
                            defaultValue="ทั้งหมด"
                            value={filter.sungkud}
                            style={{ width: 180, margin: 4 }}
                            onChange={(val) => {
                              setFilter({ ...filter, sungkud: val });
                            }}
                          >
                            <Option value={'ทั้งหมด'}>{ENG_Addrees('ทั้งหมด', lang)}</Option>
                            {[...new Set(dataFilter.map((x) => x.sungkud))]
                              .filter((x) => x !== "ไม่ระบุ")
                              .sort()
                              .map((x) => (
                                <Option value={x}>{x}</Option>
                              ))}
                          </Select>
                        </div>
                        <div>
                          <h5 className="text-sm m-0 ml-[9px]">{translate?.province}: </h5>
                          <Select
                            className="new-design-select"
                            showSearch
                            placeholder="เลือกจังหวัด"
                            defaultValue="ทั้งหมด"
                            value={filter.province_name}
                            style={{ width: 180, margin: 4 }}
                            onChange={(val) => {
                              setFilter({ ...filter, view: (val === 'ทั้งหมด' ? 'country' : 'province'), province_name: val, city_name: null, tumbon_name: null })
                            }}
                          >
                            <Option value={'ทั้งหมด'}>{ENG_Addrees('ทั้งหมด', lang)}</Option>
                            {[
                              ...new Set(
                                dataFilter
                                  .filter((item) =>
                                    filter.region !== "ทั้งหมด"
                                      ? item.region_thai === filter.region
                                      : true
                                  )
                                  .map((x) => x.province)
                              ),
                            ]
                              .filter((x) => x !== "ไม่ระบุ")
                              .sort()
                              .map((x) => (
                                <Option value={x}>{ENG_Addrees(x, lang)}</Option>
                              ))}
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-11">
                    <div className="relative w-full h-full rounded overflow-hidden shadow-card bg-slate-500">
                      <Mapbox
                        id={"map-krt"}
                        data={data_map}
                        zoom={5.2}
                        className="fullmap-cct-container"
                        style={"border_basic"}
                        filter={filter}
                        notic_title={"จำนวนโรงเรียนในประเทศไทย"}
                        onclickBorder={onclickBorder}
                        onClickMarker={onClickMarker}
                        lang={lang}
                      />
                      <div className="absolute top-[10px] left-[10px] z-1">
                        <LegendCard {...legend_data} lang={lang} />
                        <div className="justify-left mt-2 mx-auto">
                          {filter.view !== filter.minView ? (
                            <button
                              class="bg-[#fb923c] hover:bg-[#f97316] border-8 border-[#ffedd5] text-white font-bold p-2 rounded-full"
                              onClick={() => onBack()}
                            >
                              <ArrowBackIcon
                                sx={{ fontSize: 18, color: "white" }}
                              />
                              {translate?.back}
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className='absolute bottom-[50px] md:bottom-[10px] left-[100px] z-1 flex'>
                        <div className="h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3">
                          <span className="font-bold center mx-1 mb-2">
                            {translate?.PROPORTION_STDUENT_RECEIVED_SCHOLARSHIP}
                          </span>
                          <div className="flex">
                            <div className="flex flex-col">
                              <div class={`h-3 w-16 bg-[#1b5e20]`}></div>
                              <div class="font-bold text-left">{translate?.MAX}</div>
                            </div>
                            <div className="flex flex-col">
                              <div class={`h-3 w-16 bg-[#388e3c]`}></div>
                            </div>
                            <div className="flex flex-col">
                              <div class={`h-3 w-16 bg-[#66bb6a]`}></div>
                            </div>
                            <div className="flex flex-col">
                              <div class={`h-3 w-16 bg-[#a5d6a7]`}></div>
                              <div class="font-bold text-right">{translate?.MIN}</div>
                            </div>
                            <div className="ml-4  flex flex-col">
                              <div class={`h-3 w-16 bg-[#94a3b8]`}></div>
                              <div class="font-bold text-center">
                                {translate?.NO_DATA}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="absolute bottom-[10px] left-[470px] z-1">
                        <div className="w-[275px] h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3">
                          <span className="font-bold center mx-1 mb-2">
                            {translate?.TYPES_EUD_BY_AFFILIATION}
                          </span>
                          <div class="flex flex-row">
                            <div class="flex flex-row text-center mr-2">
                              <img
                                src={marker_obec}
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  margin: "auto",
                                }}
                              />
                              <span className="center m-auto">{translate?.CCT}</span>
                            </div>
                            <div class="flex flex-row text-center mr-2">
                              <img
                                src={marker_bpp}
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  margin: "auto",
                                }}
                              />
                              <span className="center m-auto">{translate?.DLA}</span>
                            </div>
                            <div class="flex flex-row text-center mr-2">
                              <img
                                src={marker_onab}
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  margin: "auto",
                                }}
                              />
                              <span className="center m-auto">{translate?.OPEC}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-[75px]"> </div>
          </div>
          <RefDataDate
            subjectTitle={translate?.PROCESSED_APR_2565}
            title={translate?.TSQP_REF}
          />
        </div>
        <div className="h-[120px]"></div>
      </div>
    </Layout >
  );
};

export default PageTSQP;
