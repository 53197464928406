import React, { useState, useEffect, useRef } from 'react';
import { navigate } from 'gatsby'
import Layout from '../components/Layout';
import RefDataDate from '../components/RefDataDate';
import Mapbox from '../mapbox'
import LegendCard from '../mapbox/LegendCard'

import Calculate from '../util/calculate';

import { Select, Tooltip } from 'antd';
import './index.css';
import imageMoney from '/images/krt-4.png';
import imageStudentsKRTHelp from '/images/krt-8.png';
import imageKRT1 from '/images/krt-2.png';
import imageKRT3 from '/images/krt-5.png';
import ImageBG from '/images/testetest.webp';

import imageMapKRT from '/images/krt-7.png';
import imageSchoolKRT from '/images/krt-6.png';
import imageKRTSchool from '/images/cct-3.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import TumbonAddr from '../util/TumbonAddr'
import region from '../util/region';
import { numberWithCommas } from '../util';
import api from '../util/api';
import useTranslation from '../hook/useTranslation'
import getProvinceName from '../util/getProvinceName'

const { Option } = Select;

const PageKRT = (props) => {
  const [initData, setInitData] = React.useState(null)
  const [data, setData] = React.useState({
    projectResultProvince: 0,
    projectResultSchool: 0,
    school_ori: 0,
    school_final: 0,
    helpStudents: 0,
    target2563: 0,
    target2564: 0,
    target2565: 0,
    target2566 : 0,
    percentY2566: 0,
    percentY2565: 0,
    percentY2564: 0,
    percentY2563: 0,
  });

  const [filter, setFilter] = React.useState({
    minView: 'country',
    view: 'country',
    year: "2566",
    region: "ทั้งหมด",
    province_name: "ทั้งหมด",
    city_name: null,
    tumbon_name: null,
  });

  const [data_map, setDataMap] = useState([])
  const [border_color] = useState(['#1b5e20', '#388e3c', '#66bb6a', '#a5d6a7', '#94a3b8',])
  const is_Click = useRef(false);
  const [T, setLang, lang] = useTranslation()
  const [legend_data, setLegendData] = useState({
    title: '',
    subtitle: '',
    data: [],
    footer: <span>*สัดส่วนในแผนที่คำนวณจากนักเรียนที่<br />จะบรรจุไปยังโรงเรียนปลายทาง</span>
  })
  const [loading, setLoading] = useState(true)

  useEffect(async () => {
    let is_login = api.isLogin().status
    let res_profile = await (is_login ? api.getProfileDb() : null)
    let res_krt = await api.getInitData('krt')
    if (res_profile) {
      if (res_profile?.profile?.roles?.includes("partner_province") && api.getActiveRole() === "partner_province") {
        let pid = res_profile?.profile?.profile?.provinceId
        setInitData({
          ...res_krt.deepdata.province[getProvinceName(pid)],
          school_ori2563: res_krt.deepdata.school_ori2563.filter(item => item.province_name === getProvinceName(pid)),
          school_ori2564: res_krt.deepdata.school_ori2564.filter(item => item.province_name === getProvinceName(pid)),
          school_ori2565: res_krt.deepdata.school_ori2565.filter(item => item.province_name === getProvinceName(pid)),
          school2563: res_krt.deepdata.school2563.filter(item => item.province_name === getProvinceName(pid)),
          school2564: res_krt.deepdata.school2564.filter(item => item.province_name === getProvinceName(pid)),
          school2565: res_krt.deepdata.school2565.filter(item => item.province_name === getProvinceName(pid)),
          university2563: res_krt.deepdata.university2563.filter(item => item.province_name === getProvinceName(pid)),
          university2564: res_krt.deepdata.university2564.filter(item => item.province_name === getProvinceName(pid)),
          university2565: res_krt.deepdata.university2565.filter(item => item.province_name === getProvinceName(pid)),
        })
        setLoading(false)
        setFilter({ ...filter, minView : "province",view: "province", province_name: getProvinceName(pid) })
      } else {
        setLoading(false)
        setInitData({ ...res_krt.deepdata })
      }
    }
  }, [])

  // useEffect(async () => {
  //   let res_krt = await api.getInitData('krt')
  //   setLoading(false)
  //   setInitData({ ...res_krt })
  // }, [])


  useEffect(() => {
    if (initData) {
      let max = Math.max(initData.count_student63, initData.count_student64, 1)
      data['projectResultProvince'] = initData.count_province66
      data['projectResultSchool'] = initData.university2566.length
      data['helpStudents'] = initData.count_student66
      data['target2563'] = initData.count_student63
      data['target2564'] = initData.count_student64
      data['target2565'] = initData.count_student65
      data['target2566'] = initData.count_student66
      data['school_ori'] = initData.school_ori2566.length
      data['school_final'] = initData.school2566.length

      data['percentY2563'] = initData.count_student63 / max * 80
      data['percentY2564'] = initData.count_student64 / max * 80
      data['percentY2565'] = initData.count_student65 / max * 80
      data['percentY2566'] = initData.count_student66 / max * 80
      setData({ ...data })
    }
  }, [initData]);

  useEffect(() => {
    if (initData) {
      let dataContextUniversity = initData['university' + filter.year]
      let dataContextSchool = initData['school' + filter.year]
      let dataContextSchoolOri = initData['school_ori' + filter.year]
      if (filter.region !== 'ทั้งหมด') {
        dataContextUniversity = dataContextUniversity.filter(item => item.region === filter.region)
        dataContextSchool = dataContextSchool.filter(item => item.region === filter.region)
        dataContextSchoolOri = dataContextSchoolOri.filter(item => item.region === filter.region)
      }
      let response = onProcessData({
        province: filter['province_name'],
        city: filter['city_name'],
        tumbon: filter['tumbon_name'],
        layer: filter.view
      }, convertMapContent(filter.view, dataContextSchool, dataContextSchoolOri, dataContextUniversity))
      let data_school = [...new Set(dataContextSchool)]
      let data_school_ori = [...new Set(dataContextSchoolOri)]
      let data_university = [...new Set(dataContextUniversity)]
      if (!filter.province_name || filter.province_name !== 'ทั้งหมด') {
        if (filter.city_name !== null) {
          data_school = [...new Set(dataContextSchool.filter(item => item.province_name === filter.province_name && item.city_name === filter.city_name))]
          data_school_ori = [...new Set(dataContextSchoolOri.filter(item => item.province_name === filter.province_name && item.city_name === filter.city_name))]
          data_university = [...new Set(dataContextUniversity.filter(item => item.province_name === filter.province_name && item.city_name === filter.city_name))]
        } else {
          data_school = [...new Set(dataContextSchool.filter(item => item.province_name === filter.province_name))]
          data_school_ori = [...new Set(dataContextSchoolOri.filter(item => item.province_name === filter.province_name))]
          data_university = [...new Set(dataContextUniversity.filter(item => item.province_name === filter.province_name))]
        }
      }
      setDataMap([
        { id: 'border', layer: filter.view, data: response.data },
        // { id: 'school_ori', main_layer: filter.view, layer: 'marker', data: data_school_ori.map(item => ({ ...item, type: 'school_ori' })), icon: Dot2 },
        // { id: 'school', main_layer: filter.view, layer: 'marker', data: data_school.map(item => ({ ...item, type: 'school' })), icon: Dot },
        // { id: 'university', main_layer: filter.view, layer: 'marker', data: data_university.map(item => ({ ...item, type: 'university' })), icon: Star },
      ])

      let data_legend = []

      if (lang === 'EN') {
        data_legend = [
          { name: 'Number of students who received scholarships', value: response.data.reduce((acc, cur) => acc + cur.krt, 0), unit: 'students' },
          { name: 'Number of participating colleges', value: response.data.reduce((acc, cur) => acc + cur.krt_university, 0), unit: 'college' },
          { name: 'Number of origin schools', value: response.data.reduce((acc, cur) => acc + cur.krt_sch_ori, 0), unit: 'schools' },
          { name: 'Number of destination schools', value: response.data.reduce((acc, cur) => acc + cur.krt_sch_des, 0), unit: 'schools' },
        ]

      } else {
        data_legend = [
          { name: 'จำนวนนักเรียนที่ได้รับทุน', value: response.data.reduce((acc, cur) => acc + cur.krt, 0), unit: 'คน' },
          { name: 'จำนวนสถาบันที่เข้าร่วม', value: response.data.reduce((acc, cur) => acc + cur.krt_university, 0), unit: 'แห่ง' },
          { name: 'จำวนโรงเรียนต้นทาง', value: response.data.reduce((acc, cur) => acc + cur.krt_sch_ori, 0), unit: 'แห่ง' },
          { name: 'จำนวนโรงเรียนปลายทาง', value: response.data.reduce((acc, cur) => acc + cur.krt_sch_des, 0), unit: 'แห่ง' },
        ]
      }
      setLegendData({
        ...legend_data,
        title: T(filter.province_name),
        subtitle: T(filter.city_name),
        data: data_legend,
        footer: (lang === 'EN' ?
          (<span>*The proportion on the map is calculated based on<br />students who will be appointed to work in destination schools.</span>) :
          (<span>*สัดส่วนในแผนที่คำนวณจากนักเรียนที่<br />จะบรรจุไปยังโรงเรียนปลายทาง</span>)
        )
      })
    }
  }, [filter, lang, initData])

  const convertMapContent = (layer, data_school_des, data_school_ori, data_university) => {
    let res = []
    const province = [...new Set(TumbonAddr.map(item => item.provincename))].map(item => { return { region: region.getRegion(item), province: item, total: 0, krt: 0, krt_sch_ori: 0, krt_sch_des: 0, krt_university: 0 } });
    const city = [...new Set(TumbonAddr.map(item => item.provincename + '_' + item.cityname))].map(item => { return { province: item.split('_')[0], city: item.split('_')[1], total: 0, krt: 0, krt_sch_ori: 0, krt_sch_des: 0, krt_university: 0 } });
    const tumbon = [...new Set(TumbonAddr.map(item => item.provincename + '_' + item.cityname + '_' + item.tumbonname))].map(item => { return { province: item.split('_')[0], city: item.split('_')[1], tumbon: item.split('_')[2], total: 0, krt: 0, krt_sch_ori: 0, krt_sch_des: 0, krt_university: 0 } });
    [data_school_des, data_school_ori, data_university].forEach((arr, idx) => {
      arr.forEach((item) => {
        let total = item.sum_krt
        let count = item.sum_krt
        if (layer === 'country') {
          let index = province.findIndex(ele => ele.province === item.province_name)
          if (index !== -1) {
            if (idx === 0) {
              !province[index]['total'] ? province[index]['total'] = total : province[index]['total'] += total
              !province[index]['krt'] ? province[index]['krt'] = count : province[index]['krt'] += count
              !province[index]['krt_sch_des'] ? province[index]['krt_sch_des'] = 1 : province[index]['krt_sch_des'] += 1
            } else if (idx === 1) {
              !province[index]['krt_sch_ori'] ? province[index]['krt_sch_ori'] = 1 : province[index]['krt_sch_ori'] += 1
            } else if (idx === 2) {
              !province[index]['krt_university'] ? province[index]['krt_university'] = 1 : province[index]['krt_university'] += 1
            }
          }
        } else if (layer === 'province') {
          let index = city.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name)
          if (index !== -1) {
            if (idx === 0) {
              !city[index]['total'] ? city[index]['total'] = total : city[index]['total'] += total
              !city[index]['krt'] ? city[index]['krt'] = count : city[index]['krt'] += count
              !city[index]['krt_sch_des'] ? city[index]['krt_sch_des'] = 1 : city[index]['krt_sch_des'] += 1
            } else if (idx === 1) {
              !city[index]['krt_sch_ori'] ? city[index]['krt_sch_ori'] = 1 : city[index]['krt_sch_ori'] += 1
            } else if (idx === 2) {
              !city[index]['krt_university'] ? city[index]['krt_university'] = 1 : city[index]['krt_university'] += 1
            }
          }
        } else if (layer === 'city') {
          let index = tumbon.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name && ele.tumbon === (item.province_id === '10' ? 'แขวง' + item.tumbon_name : item.tumbon_name))
          if (index !== -1) {
            if (idx === 0) {
              !tumbon[index]['total'] ? tumbon[index]['total'] = total : tumbon[index]['total'] += total
              !tumbon[index]['krt'] ? tumbon[index]['krt'] = count : tumbon[index]['krt'] += count
              !tumbon[index]['krt_sch_des'] ? tumbon[index]['krt_sch_des'] = 1 : tumbon[index]['krt_sch_des'] += 1
            } else if (idx === 1) {
              !tumbon[index]['krt_sch_ori'] ? tumbon[index]['krt_sch_ori'] = 1 : tumbon[index]['krt_sch_ori'] += 1
            } else if (idx === 2) {
              !tumbon[index]['krt_university'] ? tumbon[index]['krt_university'] = 1 : tumbon[index]['krt_university'] += 1
            }
          }
        } else if (layer === 'tumbon') {
          let index = tumbon.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name && ele.tumbon === (item.province_id === '10' ? 'แขวง' + item.tumbon_name : item.tumbon_name))
          if (index !== -1) {
            if (idx === 0) {
              !tumbon[index]['total'] ? tumbon[index]['total'] = total : tumbon[index]['total'] += total
              !tumbon[index]['krt'] ? tumbon[index]['krt'] = count : tumbon[index]['krt'] += count
              !tumbon[index]['krt_sch_des'] ? tumbon[index]['krt_sch_des'] = 1 : tumbon[index]['krt_sch_des'] += 1
            } else if (idx === 1) {
              !tumbon[index]['krt_sch_ori'] ? tumbon[index]['krt_sch_ori'] = 1 : tumbon[index]['krt_sch_ori'] += 1
            } else if (idx === 2) {
              !tumbon[index]['krt_university'] ? tumbon[index]['krt_university'] = 1 : tumbon[index]['krt_university'] += 1
            }
          }
        }
      })
    })
    if (layer === 'country') {
      res = province
    } else if (layer === 'province') {
      res = city
    } else if (layer === 'city') {
      res = tumbon
    } else if (layer === 'tumbon') {
      res = tumbon
    }
    if (filter.region !== 'ทั้งหมด' && filter.view === 'country') res = res.filter(item => item.region === filter.region)
    return res
  }

  const onProcessData = (filter, data) => {
    const _genColorRange = (color_set, value) => {
      color_set = color_set.sort((a, b) => b.value - a.value)
      let color = ''
      for (let i in color_set) {
        if (value > color_set[i].value) {
          color = color_set[i].color
          break;
        }
      }
      return color
    }

    let result = []
    data.forEach(element => {
      if (filter.province && filter.province !== 'ทั้งหมด' && !filter.city && !filter.tumbon) {
        if (filter.province === element.province) {
          result.push(element)
        }
      } else if (filter.province && filter.city && !filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city) {
          result.push(element)
        }
      } else if (filter.province && filter.city && filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city && filter.tumbon === element.tumbon) {
          result.push(element)
        }
      } else {
        result.push(element)
      }
    })

    result.map(element => {
      if (!isFinite(element['krt'] / element['total'])) {
        element['percent'] = 0
      } else {
        element['percent'] = ((element['krt'] / result.map(item => item.krt).reduce((a, b) => a + b, 0)) * 100)
      }
      return element
    })

    let data_color = [
      { color: border_color[0], value: Calculate.Percentile(90, result.map(item => item.krt)), count: 0, text: `มาก` },
      { color: border_color[1], value: Calculate.Percentile(50, result.map(item => item.krt)), count: 0, text: `ปานกลาง` },
      { color: border_color[2], value: Calculate.Percentile(10, result.map(item => item.krt)), count: 0, text: `ปานกลาง` },
      { color: border_color[3], value: 0, count: 0, text: `น้อย` },
      { color: border_color[4], value: -100, count: 0, text: `น้อย` },
    ]

    result.forEach(element => {
      element['color'] = _genColorRange(data_color, element.krt)
      // element['opacity'] = 0.5
      element['percent'] = parseFloat(element['percent'].toFixed(2))
    })

    let res = { data: result, notic: data_color }
    return res
  }

  const onclickBorder = (layer, filterBorder) => {
    if (layer === 'tumbon') return;
    if (!is_Click.current) {
      is_Click.current = true
      let _filter = { ...filter }
      if (layer === 'province' && filterBorder.province) {
        _filter['view'] = 'province'
        _filter['year'] = filterBorder.year
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = null
        _filter['tumbon_name'] = null
      } else if (layer === 'city' && filterBorder.city) {
        _filter['view'] = 'city'
        _filter['year'] = filterBorder.year
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = filterBorder.city
        _filter['tumbon_name'] = null
      }
      setFilter(_filter)
      setTimeout(() => {
        is_Click.current = false
      }, 1000)
    }
  }

  const onClickMarker = (property) => {
    if (!is_Click.current) {
      is_Click.current = true
      setTimeout(() => {
        is_Click.current = false
      }, 1000)
    }
  }

  const onBack = () => {
    let _filter = { ...filter }
    if (_filter.view === _filter.minView) return;
    if (_filter.view === 'province') {
      _filter['view'] = 'country'
      _filter['province_name'] = 'ทั้งหมด'
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    } else if (_filter.view === 'city') {
      _filter['view'] = 'province'
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    }
    setFilter(_filter)
  }

  // if (initData === null) return <div> loading...</div>

  return (
    <Layout
      isLoading={loading}
      Role={['public-login', 'partner', 'eef', 'partner_province']}
      backgroundImage={`url(${ImageBG})`}
      getLang={(translate, lang) => {
        setLang(lang)
      }}
    >
      <div>
        <div className='h-[120px]'></div>
        <h1 className="font-bold text-2xl md:text-3xl xl:text-5xl text-center text-white w-full ">{T('KRT_Project_TITLE')}</h1>
        <div>
          <div className="max-w-[1300px] 2xl:max-w-[1400px] rounded-3xl m-auto p-8 bg-white bordered">
            <div className="font-bold text-xl mb-2 text-[#0E3165]">{T('Project_Description')}</div>
            <p className="text-xl mb-2 font-baijam">{T('KRT_Project_Description')}</p>

            <div className="grid grid-cols-11 gap-4 w-full mt-8">

              <div className="col-span-12 md:col-span-8 lg:col-span-4">
                <div className="font-bold text-xl mb-2 text-[#0E3165]">{T('Target_Group')}</div>
                <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl  h-[210px] p-1">
                  <div className='flex flex-col justify-around '>
                    <div className="flex flex-col">
                    </div>

                    <div className="flex-col p-3">
                      <div className="text-lg lg:text-xl font-bold ">1. {T('KRT_TG_Grade_12')}</div>
                      <div className="text-md lg:text-md font-bold ml-4">- {T('KRT_TG_Grade_12_1')}</div>
                      <div className="text-md lg:text-md font-bold ml-4 ">- {T('KRT_TG_Grade_12_2')}</div>
                      <div className="text-md lg:text-md font-bold ml-4 mb-2  ">- {T('KRT_TG_Grade_12_3')}</div>
                      <div className="text-lg lg:text-xl mb-2 font-bold ">2. {T('KRT_TG_universities')}</div>
                      <div className="text-lg lg:text-xl mb-2 font-bold ">3. {T('KRT_TG_schools_in_remote')}</div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-10 lg:col-span-7">
                {/* <div className="col-span-7 lg:col-span-7"> */}
                <div className="font-bold text-xl mb-2 text-[#0E3165]">{T('Type_Support')}</div>
                <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-full lg:h-[210px] p-1">
                  <div className="font-bold text-lg m-2 text-bla ck-500  text-center">{T("KRT_TS_budget")}</div>

                  <div className="grid grid-cols-12 gap-1 w-full">

                    <div className="col-span-12 md:col-span-6 flex flex-row px-1">
                      <div className="flex flex-col m-2 w-[50px]">
                        <img className="flex self-center w-[48px]" src={imageMoney} alt="images" />
                      </div>
                      <div className="flex flex-col">
                        <div className="font-bold text-xl text-orange-400">{T('KRT_TS_budget_6000')}</div>
                        <div className="text-md text-black-500">{T('KRT_TS_budget_6000_content')}</div>
                      </div>
                    </div>

                    <div className="col-span-12 md:col-span-6 flex flex-row px-1">
                      <div className="flex flex-col m-2 w-[50px]">
                        <img className="flex self-center w-[48px]" src={imageKRT3} alt="images" />
                      </div>
                      <div className="flex flex-col">
                        <div className="font-bold text-xl text-orange-400">{T('KRT_TS_budget_2000')} </div>
                        <div className="text-md text-black-500">{T('KRT_TS_budget_2000_content')}</div>
                      </div>
                    </div>

                    <div className="col-span-12 md:col-span-6 flex flex-row px-1">
                      <div className="flex flex-col m-2 w-[50px] m">
                        <img className="flex self-center w-[48px]" src={imageKRT1} alt="images" />
                      </div>
                      <div className="flex flex-col">
                        <div className="font-bold text-xl text-orange-400">{T('KRT_TS_budget_real')}</div>
                        <div className="text-md text-black-500">{T('KRT_TS_budget_real_content1')}</div>
                      </div>
                    </div>

                    <div className="col-span-12 md:col-span-6 flex flex-row px-1">
                      <div className="flex flex-col m-2 w-[50px]">
                        <img className="flex self-center w-[48px]" src={imageMoney} alt="images" />
                      </div>
                      <div className="flex flex-col">
                        <div className="font-bold text-xl text-orange-400">{T('KRT_TS_budget_real')}</div>
                        <div className="text-md text-black-500">{T('KRT_TS_budget_real_content2')}</div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>

              <div className="col-span-12">
                <div className="font-bold text-xl mb-2 text-[#0E3165] mt-8 lg:mt-0">{T("KRT_OUTCOMES_PROJECT_2566")}</div>
                <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl p-1">

                  <div className="grid grid-cols-12 gap-1 w-full p-2">

                    <div className="col-span-12 lg:col-span-3 flex flex-row p-2">
                      <div className="flex flex-col m-2 w-[64px]">
                        <img className="flex self-center w-[64px]" src={imageSchoolKRT} alt="images" />
                      </div>

                      {/* : (1) coverage in 45 provinces (2) 10 institutions participating in the project (3) 208 origin schools (4) 268 destination schools  */}
                      {
                        lang === 'EN' ?
                          <div className="flex flex-col">
                            <div className="font-bold text-xl text-black-500 mt-1">coverage in </div>
                            <div className="font-bold text-xl text-orange-400">{numberWithCommas(data.projectResultProvince)} provinces</div>
                          </div>
                          :
                          <div className="flex flex-col">
                            <div className="font-bold text-xl text-black-500 mt-1">ครอบคลุม</div>
                            <div className="font-bold text-xl text-orange-400">{numberWithCommas(data.projectResultProvince)} จังหวัด</div>
                          </div>
                      }
                    </div>

                    <div className="col-span-12 lg:col-span-3 flex flex-row p-2">
                      <div className="flex flex-col m-2 w-[64px]">
                        <img className="flex self-center w-[64px]" src={imageMapKRT} alt="images" />
                      </div>
                      {
                        lang === 'EN' ?
                          <div className="flex flex-col">
                            <div className="font-bold text-xl text-orange-400 mt-1 ">{numberWithCommas(data.projectResultSchool)} institutions</div>
                            <div className="font-bold text-xl text-black-500 ">participating in the project</div>
                          </div>
                          :
                          <div className="flex flex-col">
                            <div className="font-bold text-xl text-black-500 mt-1">สถาบันที่เข้าร่วมโครงการ</div>
                            <div className="font-bold text-xl text-orange-400">{numberWithCommas(data.projectResultSchool)} แห่ง</div>
                          </div>
                      }

                    </div>

                    <div className="col-span-12 lg:col-span-3 flex flex-row p-2">
                      <div className="flex flex-col m-2 w-[64px]">
                        <img className="flex self-center w-[64px]" src={imageKRTSchool} alt="images" />
                      </div>
                      {
                        lang === 'EN' ?
                          <div className="flex flex-col">
                            <div className="font-bold text-xl text-orange-400 mt-1 ">{numberWithCommas(data.school_ori)}</div>
                            <div className="font-bold text-xl text-black-500 ">origin schools</div>
                          </div>
                          :
                          <div className="flex flex-col">
                            <div className="font-bold text-xl text-black-500 mt-1">โรงเรียนต้นทาง</div>
                            <div className="font-bold text-xl text-orange-400">{numberWithCommas(data.school_ori)} แห่ง</div>
                          </div>
                      }

                    </div>

                    <div className="col-span-12 lg:col-span-3 flex flex-row p-2">
                      <div className="flex flex-col m-2 w-[64px]">
                        <img className="flex self-center w-[64px]" src={imageKRTSchool} alt="images" />
                      </div>
                      {
                        lang === 'EN' ?
                          <div className="flex flex-col">
                            <div className="font-bold text-xl text-orange-400 mt-1 ">{numberWithCommas(data.school_final)}</div>
                            <div className="font-bold text-xl text-black-500 ">destination schools</div>
                          </div>
                          :
                          <div className="flex flex-col">
                            <div className="font-bold text-xl text-black-500 mt-1">โรงเรียนปลายทาง</div>
                            <div className="font-bold text-xl text-orange-400">{numberWithCommas(data.school_final)} แห่ง</div>
                          </div>
                      }

                    </div>

                  </div>

                </div>
              </div>

              <div className="col-span-12 md:col-span-5 lg:col-span-3">
                <div className="mt-8 lg:mt-0"></div>
                <div className="flex justify-center items-center w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-[320px] lg:h-[150] p-1">

                  {
                    lang === 'EN' ?
                      <div className="px-6 py-4 my-5">
                        <div className='flex justify-center'>
                          <img className="flex self-center" src={imageStudentsKRTHelp} alt="image for banner" width={'100px'} />
                        </div>
                        <div className='flex flex-row justify-center'>
                          <div className="flex flex-col ml-4">
                            <div className="font-bold text-xl mb-2 text-center">{`${data?.helpStudents?.toLocaleString('en-US')}`} students who received scholarships in the Academic Year 2023</div>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="px-6 py-4 my-5">
                        <div className="font-bold text-lg lg:text-xl mb-4 text-[#0E3165] text-center">นักเรียนที่ได้รับทุนปีการศึกษา 2566</div>
                        <div className='flex flex-row justify-center'>
                          <img className="flex self-center" src={imageStudentsKRTHelp} alt="image for banner" width={'100px'} />
                          <div className="flex flex-col ml-4">
                            <div className="font-bold text-3xl mb-2 text-[#048967]">{`${data?.helpStudents?.toLocaleString('en-US')}`}</div>
                            <div className="font-bold text-xl mb-2">คน</div>
                          </div>
                        </div>
                      </div>
                  }
                </div>
              </div>

              <div className="col-span-11 lg:col-span-8">
                <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl  h-[320px]  lg:h-[150] p-1">
                  <div className="font-bold text-md md:text-lg lg:text-xl mb-2 text-[#0E3165] text-center">{T("NO_STUDENTS_SCHOLARSHIPS_AY")}</div>
                  <div className='border-t-2 w-full h-[0.5px]'></div>
                  <div className="flex flex-col px-6 py-4">
                    < div className="font-bold text-lg lx:text-xl mt-2 text-black-800">{T('academic_year')} {T("Y2566")}</div>
                    <div className="w-full flex flex-row content-center " >
                      <div
                        style={{ width: data.percentY2566 * 0.95 + '%' }}
                        className={`transition-all ease-out duration-1000 h-[18px] bg-green-700 relative rounded-md`}
                      />
                      < div className="font-bold text-sm lg:text-md ml-2 text-bla ck-800">{`${data?.target2566?.toLocaleString('en-US')} `}</div>
                      <div className='hidden lg:flex ml-1'>{T('students')}</div>
                      <div className='flex lg:hidden ml-1'>{T('students_short')}</div>
                    </div>

                    < div className="font-bold text-lg lx:text-xl mt-2 text-black-800">{T('academic_year')} {T("Y2565")}</div>
                    <div className="w-full flex flex-row content-center " >
                      <div
                        style={{ width: data.percentY2565 * 0.95 + '%' }}
                        className={`transition-all ease-out duration-1000 h-[18px] bg-green-700 relative rounded-md`}
                      />
                      < div className="font-bold text-sm lg:text-md ml-2 text-bla ck-800">{`${data?.target2565?.toLocaleString('en-US')} `}</div>
                      <div className='hidden lg:flex ml-1'>{T('students')}</div>
                      <div className='flex lg:hidden ml-1'>{T('students_short')}</div>
                    </div>

                    < div className="font-bold text-lg lx:text-xl mt-2 text-black-800">{T('academic_year')} {T("Y2564")}</div>
                    <div className="w-full flex flex-row content-center " >
                      <div
                        style={{ width: data.percentY2564 * 0.95 + '%' }}
                        className={`transition-all ease-out duration-1000 h-[18px] bg-green-400 relative rounded-md`}
                      />
                      < div className="font-bold text-sm lg:text-md ml-2 text-bla ck-800">{`${data?.target2564?.toLocaleString('en-US')} `}</div>
                      <div className='hidden lg:flex ml-1'>{T('students')}</div>
                      <div className='flex lg:hidden ml-1'>{T('students_short')}</div>
                    </div>

                    < div className="font-bold text-lg lx:text-xl mt-2 text-black-800">{T('academic_year')} {T("Y2563")}</div>
                    <div className="w-full flex flex-row content-center " >
                      <div
                        style={{ width: data.percentY2563 * 0.95 + '%' }}
                        className={`transition-all ease-out duration-1000 h-[18px] bg-green-400 relative rounded-md`}
                      />
                      < div className="font-bold text-sm lg:text-md ml-2 text-bla ck-800">{`${data?.target2563?.toLocaleString('en-US')}`} </div>
                      <div className='hidden lg:flex ml-1'>{T('students')}</div>
                      <div className='flex lg:hidden ml-1'>{T('students_short')}</div>
                    </div>


                  </div>
                </div>
              </div>
            </div>

            <div className="max-w rounded-xl overflow-hidden shadow-[0_0px_16px_rgba(17,17,26,0.1)] bg-white w-full mt-10 p-2">

              <header className="text-gray-600 body-font">
                <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                  <nav className="flex lg:w-1/5 flex-wrap items-center text-base md:ml-auto">
                  </nav>
                  <p className="w-3/5 items-center justify-center font-bold text-2xl mb-2  text-[#048967] text-center">
                    {T('KRT_MAP')}
                  </p>
                  <div className="lg:w-1/5 inline-flex lg:justify-end ml-5 lg:ml-0">
                    {
                      lang !== 'EN' && <button class="inline-flex items-center bg-emerald-500 border py-1 px-3 focus:outline-none hover:bg-emerald-700 rounded-full text-base mt-4 md:mt-0 text-white"
                        onClick={() => navigate('/fullmap-krt')}>
                        สำรวจข้อมูลโครงการ
                        <svg fill="none" stroke="currentColor" s troke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-1" viewBox="0 0 24 24">
                          <path d="M5 12h14M12 5l7 7-7 7"></path>
                        </svg>
                      </button>
                    }
                  </div>

                </div >
              </header >

              <div className="w-full h-[1px] bg-gray-300 mb-4" > </div>

              <div className="grid grid-cols-11 gap-4 w-full">
                <div className="col-span-11 justify-center">
                  <div className="flex flex-col p-0 m-0">
                    < div className="flex flex-col md:flex-row " >
                      <div>
                        <h5 className="text-sm m-0 ml-[9px]" >{T('year')} : </h5>
                        <Select
                          className='new-design-select'
                          showSearch
                          placeholder="ปีการศึกษา"
                          value={filter.year}
                          style={{ width: 180, margin: 4 }}
                          onChange={(val) => {
                            setFilter({ ...filter, year: val })
                          }}
                        >
                          {
                            [
                              { value: '2566', text: T('Y2566') },
                              { value: '2565', text: T('Y2565') },
                              { value: '2564', text: T('Y2564') },
                              { value: '2563', text: T('Y2563') },
                            ]
                              .filter(x => x.value !== 'ไม่ระบุ')
                              .sort()
                              .map(x => <Option value={x.value}>{x.text}</Option>)
                          }

                        </Select>
                      </div>
                      <div>
                        <h5 className="text-sm m-0 ml-[9px]" >{T('region')}  : </h5>
                        <Select
                          className='new-design-select'
                          showSearch
                          placeholder="ภาค"
                          defaultValue="ทั้งหมด"
                          value={filter.region}
                          style={{ width: 180, margin: 4 }}
                          onChange={(val) => {
                            setFilter({ ...filter, region: val })
                          }}
                        >
                          <Option value={'ทั้งหมด'}>{T('ทั้งหมด')}</Option>
                          {
                            initData &&
                            [...new Set(initData['school' + filter.year].map(x => x.region))]
                              .filter(x => x !== 'ไม่ระบุ')
                              .sort()
                              .map(x => <Option value={x}>{T(x)}</Option>)
                          }
                        </Select>
                      </div>
                      <div>
                        <h5 className="text-sm m-0 ml-[9px]" >{T('province')} : </h5>
                        <Select
                          className='new-design-select'
                          showSearch
                          placeholder="เลือกจังหวัด"
                          defaultValue="ทั้งหมด"
                          value={filter.province_name}
                          style={{ width: 180, margin: 4 }}
                          onChange={(val) => {
                            setFilter({ ...filter, view: (val === 'ทั้งหมด' ? 'country' : 'province'), province_name: val, city_name: null, tumbon_name: null })
                          }}
                        >
                          <Option value={'ทั้งหมด'}>{T('ทั้งหมด')}</Option>
                          {
                            initData &&
                            [...new Set(initData['school' + filter.year].filter(item => filter.region !== 'ทั้งหมด' ? item.region === filter.region : true).map(x => x.province_name))]
                              .filter(x => x !== 'ไม่ระบุ')
                              .sort()
                              .map(x => <Option value={x}>{T(x)}</Option>)
                          }
                        </Select>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-span-11">
                  <div className="relative w-full h-full rounded overflow-hidden shadow-card bg-slate-500">
                    <Mapbox
                      id={'map-krt'}
                      data={data_map}
                      zoom={5.2}
                      className="fullmap-cct-container"
                      style={"border_basic"}
                      filter={filter}
                      notic_title={'จำนวนโรงเรียนในประเทศไทย'}
                      onclickBorder={onclickBorder}
                      onClickMarker={onClickMarker}
                      lang={lang}
                    />
                    <div className='absolute top-[10px] left-[10px] z-1'>
                      <LegendCard {...legend_data} lang={lang} />
                      <div className='justify-left mt-2 mx-auto'>
                        {filter.view !== filter.minView ? <button class="bg-[#fb923c] hover:bg-[#f97316] border-8 border-[#ffedd5] text-white font-bold p-2 rounded-full" onClick={() => onBack()}>
                          <ArrowBackIcon sx={{ fontSize: 18, color: 'white' }} /> {T("back")}
                        </button> : <></>}
                      </div>
                    </div>
                    <div className='absolute bottom-[50px] md:bottom-[10px] left-[100px] z-1 flex'>
                      <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
                        <span className='font-bold center mx-1 mb-2 text-[10px] md:text-md'>
                          {
                            lang === 'EN' ?
                              'The proportion of students who will be appointed to work in destination schools.' :
                              'สัดส่วนนักเรียนที่จะบรรจุไปยังโรงเรียนปลายทาง'
                          }
                          &nbsp;
                        </span>
                        <div className='flex'>
                          <div className='flex flex-col'>
                            <div class={`h-3 w-8 md:w-16  bg-[#1b5e20]`}>
                            </div>
                            <div class="font-bold text-center text-[10px] md:text-md">
                              {T("MAX")}
                            </div>
                          </div>
                          <div className='flex flex-col'>
                            <div class={`h-3 w-8 md:w-16  bg-[#388e3c]`}>
                            </div>
                          </div>
                          <div className='flex flex-col'>
                            <div class={`h-3 w-8 md:w-16  bg-[#66bb6a]`}>
                            </div>
                          </div>
                          <div className='flex flex-col'>
                            <div class={`h-3 w-8 md:w-16  bg-[#a5d6a7]`}>
                            </div>
                            <div class="font-bold text-center text-[10px] md:text-md">
                              {T("MIN")}
                            </div>
                          </div>
                          <div className='ml-4  flex flex-col'>
                            <div class={`h-3 w-8 md:w-16  bg-[#94a3b8]`}>
                            </div>
                            <div class="font-bold text-center text-[10px] md:text-md">
                              {T("NO_DATA")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div >
            <RefDataDate
              title={T('KRT_REF')}
              subjectTitle={T('PROCESSED_APR_2566')}
            />
          </div >
        </div >
        <div className='h-[120px]'></div>
      </div >
    </Layout >
  )
}

export default PageKRT
