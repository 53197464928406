
import * as React from 'react';
// import data_school from '.';
import datacity from './datacity.json';
import {
  Select,
} from 'antd';
const { Option } = Select;


let YEAR_SUNGKUD = {
  '2567_1': ['สพฐ.', 'อปท.', 'ตชด.', 'สช.', 'พศ.', 'กทม.'],
  '2566_2': ['สพฐ.', 'อปท.', 'ตชด.', 'สช.', 'พศ.', 'กทม.'],
  '2566_1': ['สพฐ.', 'อปท.', 'ตชด.', 'สช.', 'พศ.', 'กทม.'],
  '2565_2': ['สพฐ.', 'อปท.', 'ตชด.', 'สช.', 'พศ.', 'กทม.'],
  '2565_1': ['สพฐ.', 'อปท.', 'ตชด.', 'สช.', 'พศ.'],
  '2564_2': ['สพฐ.', 'อปท.', 'ตชด.', 'สช.', 'พศ.'],
  '2564_1': ['สพฐ.', 'อปท.', 'ตชด.', 'พศ.',],
  '2563_2': ['สพฐ.', 'อปท.', 'ตชด.', 'พศ.'],
  '2563_1': ['สพฐ.', 'อปท.', 'ตชด.'],
  '2562_2': ['สพฐ.', 'อปท.', 'ตชด.'],
  '2562_1': ['สพฐ.', 'อปท.', 'ตชด.'],
  '2561_2': ['สพฐ.', 'อปท.', 'ตชด.'],
}
// sungkud
export default function Filter({ onChange, data, optionYear = [] }) {
  console.log('datadatadatadatadatadata',data)
  // city
  return (
    <div className="code-box-shape4 queue-anim-demo flex flex-col md:flex-row w-full md:w-min" >

      <div >
        <h5 className="text-sm m-0 ml-[9px]" >ภาคเรียน: </h5>
        <div className='flex w-full md:w-[120px]'>
          <Select
            className='new-design-select'
            placeholder="ภาคเรียน"
            showSearch
            value={`${data.semester}/${data.year}`}
            onChange={(val) => {
              let y = val.split('/')[1]
              let s = val.split('/')[0]
              onChange({
                ...data,
                ...{
                  semester: s,
                  year: y,
                  sungkud: "ทั้งหมด",
                }
              })
            }}
            style={{ margin: 4, width: '100%', fontFamily: 'Kanit' }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            } 
          >

            {/* sungkud */}
            <Option value={'1/2567'}>{'1/2567'}</Option>
            <Option value={'2/2566'}>{'2/2566'}</Option>
            <Option value={'1/2566'}>{'1/2566'}</Option>
            <Option value={'2/2565'}>{'2/2565'}</Option>
            <Option value={'1/2565'}>{'1/2565'}</Option>
            <Option value={'2/2564'}>{'2/2564'}</Option>
            <Option value={'1/2564'}>{'1/2564'}</Option>
            <Option value={'2/2563'}>{'2/2563'}</Option>
            <Option value={'1/2563'}>{'1/2563'}</Option>
            <Option value={'2/2562'}>{'2/2562'}</Option>
            <Option value={'1/2562'}>{'1/2562'}</Option>
            <Option value={'2/2561'}>{'2/2561'}</Option>

          </Select>
        </div>
        {/* md={{ width: 120 }} */}


      </div>

      <div>
        <h5 className="text-sm m-0 ml-[9px]" >สังกัด: </h5>
        <div className='flex w-full md:w-[120px]'>
          <Select
            className='new-design-select'
            placeholder="สังกัด"
            showSearch
            value={data.sungkud}
            onChange={(val) => {
              onChange({ ...data, sungkud: val })
            }}
            style={{ margin: 4, width: '100%', fontFamily: 'Kanit' }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={'ทั้งหมด'}>{'ทั้งหมด'}</Option>
            {
              YEAR_SUNGKUD[`${data.year}_${data.semester}`] ?
                YEAR_SUNGKUD[`${data.year}_${data.semester}`].map(x => <Option value={x}>{x}</Option>)
                : <></>
            }
            {/* {
              data.year === ''
              sungkud
            } */}
            {/* <Option value={'สพฐ.'}>{'สพฐ.'}</Option>
            <Option value={'อปท.'}>{'อปท.'}</Option>
            <Option value={'ตชด.'}>{'ตชด.'}</Option>
            <Option value={'สช.'}>{'สช.'}</Option>
            <Option value={'พศ.'}>{'พศ.'}</Option>
            <Option value={'กทม.'}>{'กทม.'}</Option> */}

          </Select>
        </div>
      </div>

      <div>
        <h5 className="text-sm m-0 ml-[9px]" >จังหวัด: </h5>
        <div className='flex w-full md:w-[120px]'>
          <Select
            className='new-design-select'
            placeholder="จังหวัด"
            showSearch
            value={data.province_name}
            onChange={(val) => {
              if (val === 'ทั้งหมด') {
                onChange({
                  ...data,
                  ...{
                    province_name: "ทั้งหมด",
                    view: 'country',
                    city_name: null,
                  }
                })

              } else {
                onChange({
                  ...data,
                  ...{
                    province_name: val,
                    view: 'province',
                    city_name: null,
                  }
                })
              }
            }}
            style={{ margin: 4, width: '100%', fontFamily: 'Kanit' }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={'ทั้งหมด'}>{'ทั้งหมด'}</Option>
            {
              [, ...new Set(datacity.map(x => x.province))]
                .filter(x => x !== 'ไม่ระบุ')
                .sort()
                .map(x => <Option value={x}>{x}</Option>)
            }
          </Select>
        </div>
      </div>

      <div>
        <h5 className="text-sm m-0 ml-[9px]" >อำเภอ: </h5>
        <div className='flex w-full md:w-[120px]'>
          <Select
            className='new-design-select'
            showSearch
            value={data.city_name}
            placeholder="เลือกอำเภอ"
            style={{ margin: 4, width: '100%', fontFamily: 'Kanit' }}
            disabled={data.view === 'country'}
            onChange={(val) => {
              if (val === "ทั้งหมด") {
                onChange({
                  // city_name: "ทั้งหมด",
                  // view: 'city_name',
                })
              } else {
                onChange({
                  ...data,
                  ...{
                    view: 'city',
                    city_name: val,
                  }
                })
              }

            }}
          >
            {
              [...new Set(datacity.filter(x => data.province_name === x.province || data.province_name === null).map(x => x.district))]
                .sort()
                .filter(x => x !== 'ไม่ระบุ')
                .map(x => <Option value={x}>{x}</Option>)
            }
          </Select>
        </div>
      </div>

    </div >

  )
}